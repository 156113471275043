import { Inject, Injectable } from '@angular/core';
import { IPatientService } from './patient-service.interface';
import { catchError, mergeMap, Observable, of } from 'rxjs';
import {
  IPatient,
  IPatientResetRequest,
  IPatientSearch,
  ITreatment,
  ITreatmentsSearchCondition,
  IUpdatePatientPayload,
} from '@main-data-access-interfaces';
import { HttpClient } from '@angular/common/http';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@main-data-access-resolvers';
import { SearchResult } from '@main-data-access-models';

@Injectable()
export class PatientService implements IPatientService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endpointResolver: IApiEndpointResolver,
    private readonly _httpClient: HttpClient
  ) {}

  //#endregion Constructor

  //#region Methods

  public getAsync(
    conditions: Partial<IPatientSearch>
  ): Observable<SearchResult<IPatient>> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/patients/search`;
        return this._httpClient.post<SearchResult<IPatient>>(
          apiUrl,
          conditions
        );
      })
    );
  }

  public getByMrnAsync(mrn: string): Observable<IPatient | undefined> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/patients/by-mrn/${mrn}`;
        return this._httpClient.get<IPatient>(apiUrl);
      }),
      catchError((exception) => {
        return of(undefined);
      })
    );
  }

  public resetPatientProfileAsync(
    mrn: string,
    payload: IPatientResetRequest
  ): Observable<IPatient> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/patients/${mrn}/profile/reset`;
        return this._httpClient.put<IPatient>(apiUrl, payload);
      })
    );
  }

  public updatePatientByMrnAsync(
    mrn: string,
    payload: IUpdatePatientPayload
  ): Observable<IPatient> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/patients/${mrn}`;
        return this._httpClient.put<IPatient>(apiUrl, payload);
      })
    );
  }

  public searchTreatmentsAsync(
    condition: ITreatmentsSearchCondition
  ): Observable<SearchResult<ITreatment>> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/patients/treatments/search`;
        return this._httpClient.post<SearchResult<ITreatment>>(
          apiUrl,
          condition
        );
      })
    );
  }

  public createTreatmentAsync(
    mrn: string,
    payload: Partial<ITreatment>
  ): Observable<ITreatment> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/patients/${mrn}/treatment`;
        return this._httpClient.post<ITreatment>(apiUrl, payload);
      })
    );
  }

  //#endregion Methods
}

import { Inject, Injectable } from '@angular/core';
import { IDiagnosisService } from './diagnosis-service.interface';
import { mergeMap, Observable } from 'rxjs';
import { IDiagnosis } from '@main-data-access-interfaces';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@main-data-access-resolvers';
import { SearchResult } from '@main-data-access-models';

@Injectable()
export class DiagnosisService implements IDiagnosisService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endpointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion Constructor

  //#region Methods

  public getDiagnosisAsync(): Observable<{ id: number; name: string }[]> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        return this._httpClient.get<{ id: number; name: string }[]>(
          `${baseUrl}/diagnoses`
        );
      })
    );
  }

  public getDiagnosesByPatientMrnAsync(
    mrn: string
  ): Observable<SearchResult<IDiagnosis>> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        return this._httpClient.post<SearchResult<IDiagnosis>>(
          `${baseUrl}/diagnosis/set/search`,
          {
            mrn: mrn,
          }
        );
      })
    );
  }

  public getByDiseaseIdAsync(
    diseaseId: number
  ): Observable<{ id: number; name: string; diseaseId: number }[]> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        return this._httpClient.get<
          { id: number; name: string; diseaseId: number }[]
        >(`${baseUrl}/diagnoses/by-disease-id/${diseaseId}`);
      })
    );
  }

  public getDiagnosesAsync(
    diseaseId?: number
  ): Observable<{ id: number; name: string; diseaseId: number }[]> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        let httpParams: HttpParams = new HttpParams();
        if (diseaseId) {
          httpParams = httpParams.append('diseaseId', diseaseId);
        }
        return this._httpClient.get<
          { id: number; name: string; diseaseId: number }[]
        >(`${baseUrl}/diagnoses`, { params: httpParams });
      })
    );
  }

  //#endregion Methods
}

import {NavigateToScreenRequest} from '@ui-tool/core';
import {ScreenCodes} from '@main-data-access-enums';

export class UnavailableUserPageNavigationRequest extends NavigateToScreenRequest<void> {

  //#region Constructor

  public constructor() {
  super(ScreenCodes.UNAVAILABLE_USER);
}

  //#endregion

}

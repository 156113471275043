import { portalLayoutStoreInitialState } from './portal-layout.adapter';
import { PortalLayoutActions } from './portal-layout.actions';
import { createReducer, on } from '@ngrx/store';

//#region Properties

export const portalLayoutStoreKey = 'portal_layout';

export const portalLayoutReducer = createReducer(
  portalLayoutStoreInitialState,
  on(PortalLayoutActions.toggleCollapse, (state, action) => ({
    ...state,
    collapse: !state.collapse,
  })),
  on(PortalLayoutActions.showLoading, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(PortalLayoutActions.hideLoading, (state, action) => ({
    ...state,
    loading: false,
  }))
);

//#endregion

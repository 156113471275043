import { NgModule } from '@angular/core';
import { DSBadgeComponent } from './badge.component';
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { DSIconModule } from '../icon';

@NgModule({
  declarations: [DSBadgeComponent],
  exports: [DSBadgeComponent],
  imports: [NgSwitch, NgSwitchDefault, NgSwitchCase, NgIf, DSIconModule],
})
export class DSBadgeModule {}

<div class="ds-checkbox ds-checkbox--{{ size }}">
  <input
    [id]="id"
    [disabled]="disabled"
    [readOnly]="readOnly"
    [class.has-error]="hasError"
    [checked]="forceChecked != null ? forceChecked : value"
    type="checkbox"
    (change)="select($event)"
  />
  <label [for]="id">
    <strong *ngIf="label">{{ label }}</strong>
    <small *ngIf="description">{{ description }}</small>
  </label>
</div>

import { Directive, ElementRef, Input } from '@angular/core';
import { DSControlDirective, DSControlTypes } from '../control';

@Directive({
  selector: 'input[mOcloudDSInput], textarea[mOcloudDSInput]',
  providers: [
    {
      provide: DSControlDirective,
      useExisting: DSInputDirective,
    },
  ],
  host: {
    '[class]': '"ds-input"',
    '[class.has-error]': 'hasError',
  },
})
export class DSInputDirective extends DSControlDirective<string | number> {
  @Input()
  public hasError = false;

  public get isDisabled(): boolean {
    return this.elementRef.nativeElement.disabled;
  }

  public get isReadonly(): boolean {
    return this.elementRef.nativeElement.readOnly;
  }

  public get value(): string | number {
    return this.elementRef.nativeElement.value;
  }

  public controlType: DSControlTypes = DSControlTypes.INPUT;

  public constructor(
    public readonly elementRef: ElementRef<
      HTMLInputElement | HTMLTextAreaElement
    >
  ) {
    super();
  }
}

import {IAppSettings, IOpenAuthenticationOptions} from "@main-data-access-interfaces";

export class AppSettings implements IAppSettings {

  //#region Properties

  public apiUrl?: string;

  public openAuthenticationOptions?: IOpenAuthenticationOptions[];

  //#endregion

}

<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'day'">
    <button
      class="ds-date-picker-item {{ customClasses }}"
      (click)="itemClick.emit(value)"
      [disabled]="disabled"
    >
      <label>{{ text }}</label>
      <span *ngIf="isToday"></span>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'week-day'">
    <span class="ds-date-picker-item {{ customClasses }}">{{ text }}</span>
  </ng-container>
</ng-container>

import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { catchError, map, mergeMap, Observable, of, throwError } from 'rxjs';
import {
  AUTHENTICATION_SERVICE,
  IAuthenticationService,
} from '@main-data-access-services';
import {
  ISmartNavigatorService,
  NavigateToScreenRequest,
  SMART_NAVIGATOR_SERVICE,
} from '@ui-tool/core';
import { ExceptionCodes } from '@main-data-access-enums';
import {
  LoginPageNavigationRequest,
  UnavailableUserPageNavigationRequest,
} from '@main-data-access-models';
import { Store } from '@ngrx/store';
import { IProfileState, ProfileActions } from '@main-data-access-stores';

@Injectable()
export class AuthenticatedUserGuard implements CanActivate {
  //#region Constructor

  public constructor(
    @Inject(AUTHENTICATION_SERVICE)
    protected readonly _authenticationService: IAuthenticationService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    protected readonly _store: Store<IProfileState>
  ) {}

  //#endregion

  //#region Methods

  public canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    routerStateSnapshot: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._authenticationService.getProfileAsync().pipe(
      mergeMap((profile) => {
        if (!profile) {
          return throwError(() => ExceptionCodes.ACCESS_TOKEN_NOT_FOUND);
        }
        this._store.dispatch(ProfileActions.saveProfile({ profile }));
        return of(profile);
      }),
      map(() => true),
      catchError((exception) => {
        return this._authenticationService.deleteAccessTokenAsync().pipe(
          mergeMap(() => {
            let navigationRequest: NavigateToScreenRequest<any>;
            if (exception === ExceptionCodes.PROFILE_NOT_FOUND) {
              navigationRequest = new UnavailableUserPageNavigationRequest();
            } else {
              navigationRequest = new LoginPageNavigationRequest();
            }
            const urlTree = this._navigationService.buildUrlTree(
              navigationRequest.code,
              navigationRequest.routeParams as any,
              navigationRequest.extras
            );
            return of(urlTree);
          })
        );
      })
    );
  }

  //#endregion
}

import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IAppointmentDetailState} from './appointment-detail.state';
import {appointmentDetailStoreKey} from './appointment-detail.reducer';

const selectAppointmentDetail =
  createFeatureSelector<IAppointmentDetailState>(appointmentDetailStoreKey);


export class AppointmentDetailSelectors {

  //#region Properties

  public static readonly appointment = createSelector(selectAppointmentDetail, x => x.appointment);

  //#endregion

}

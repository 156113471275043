<m-ocloud-ds-button-group [activeKey]="index.toString()">
  <ng-template
    (clickEvent)="previous()"
    mOcloudDSButtonGroupItem
    kind="icon-large"
    [disableSelectAction]="true"
    [instance]="'arrow-left'"
    [instanceCategory]="'outline'"
  ></ng-template>
  <ng-container *ngFor="let item of items">
    <ng-template
      (clickEvent)="item !== '...' && selectPage(item)"
      mOcloudDSButtonGroupItem
      [key]="item.toString()"
      [instance]="(item !== '...' ? item + 1 : item).toString()"
      [disableSelectAction]="item === '...'"
      [disableState]="item === '...'"
    ></ng-template>
  </ng-container>
  <ng-template
    (clickEvent)="next()"
    mOcloudDSButtonGroupItem
    kind="icon-large"
    [disableSelectAction]="true"
    [instance]="'arrow-right-1'"
    [instanceCategory]="'outline'"
  ></ng-template>
</m-ocloud-ds-button-group>

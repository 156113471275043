import {createReducer, on} from "@ngrx/store";
import {PatientDetailActions} from './patient-detail.actions';
import { IPatientDetailState } from "./patient-detail.state";

//#region Properties

export const patientDetailStoreKey = 'patientDetailStoreKey';
const patientDetailState: IPatientDetailState = {
  patient: undefined
};

export const patientDetailReducer = createReducer(
  patientDetailState,
  on(PatientDetailActions.setPatient, (state, action) => ({
    ...state,
    patient: action.patient
  }))
);

//#endregion

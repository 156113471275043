<div class="ds-button-group" [class.ignore-item-border]="ignoreItemBorder">
  <ng-container *ngFor="let item of renderItems; let i = index">
    <button
      [disabled]="item.disabled"
      [class]="item.customClasses"
      [class.disabled-state]="item.disableState"
      [class.ds-button-group--is-icon]="item.kind === 'icon'"
      [class.ds-button-group--is-icon-large]="item.kind === 'icon-large'"
      [class.ds-button-group--is-selected]="(activeKey$ | async) === item.key"
      (click)="itemClick(item)"
    >
      <ng-container [ngSwitch]="item.kind">
        <ng-container *ngSwitchCase="'icon'">
          <i
            *ngIf="item.instance"
            [category]="item.instanceCategory"
            [mOcloudDSIcon]="item.instance"
          ></i>
        </ng-container>
        <ng-container *ngSwitchCase="'icon-large'">
          <i
            *ngIf="item.instance"
            [category]="item.instanceCategory"
            [mOcloudDSIcon]="item.instance"
          ></i>
        </ng-container>
        <ng-container *ngSwitchCase="'custom'">
          <ng-container *ngTemplateOutlet="item.templateRef"></ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <i
            *ngIf="item.iconLeft"
            [category]="item.iconLeftCategory"
            [mOcloudDSIcon]="item.iconLeft"
          ></i>
          <span>{{ item.instance }}</span>
          <i
            *ngIf="item.iconRight"
            [category]="item.iconRightCategory"
            [mOcloudDSIcon]="item.iconRight"
          ></i>
        </ng-container>
      </ng-container>
    </button>
  </ng-container>
</div>

import { Directive, Input, TemplateRef } from '@angular/core';
import { DSSelectItemKinds, DSSelectItemTypes } from '../enums';
import { DSIconCategories } from '@design-system';
import { IDSSelectItem } from '../interfaces';

@Directive({
  selector: 'ng-template[mOcloudDSSelectItem]',
})
export class DSSelectItemDirective<TType extends any>
  implements IDSSelectItem<TType>
{
  @Input()
  public text = '';

  @Input()
  public value: TType | null = null;

  @Input()
  public kind: DSSelectItemKinds | `${DSSelectItemKinds}` =
    DSSelectItemKinds.DEFAULT;

  @Input()
  public type: DSSelectItemTypes | `${DSSelectItemTypes}` =
    DSSelectItemTypes.DEFAULT;

  @Input()
  public icon = 'tick-square';

  @Input()
  public iconCategory: DSIconCategories | `${DSIconCategories}` =
    DSIconCategories.BOLD;

  @Input()
  public disabled = false;

  @Input()
  public iconCustomClasses = '';

  @Input()
  public customClasses = '';

  public constructor(public readonly templateRef: TemplateRef<HTMLElement>) {}
}

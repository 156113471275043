import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { compareDesc, getDay, getDaysInMonth } from 'date-fns';
import { IDSDatePickerItem } from '../../interfaces';
import { DSDatePickerTypes } from '../../enums';
import { DSDatePickerValue } from '../../types';
import { isEqual } from 'lodash-es';

@Component({
  selector: 'm-ocloud-ds-date-picker-date',
  templateUrl: './date-picker-date.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '"contents"',
  },
})
export class DSDatePickerDateComponent<
  TType extends DSDatePickerTypes | `${DSDatePickerTypes}`,
  TValue = DSDatePickerValue<TType>
> implements OnChanges
{
  @Output()
  public readonly selectedDate = new EventEmitter<Date>();

  @Input()
  public weekDays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

  @Input()
  public date = new Date();

  @Input()
  public min: Date | null = null;

  @Input()
  public max: Date | null = null;

  @Input()
  public value: TValue | null = null;

  @Input()
  public type: TType | DSDatePickerTypes | `${DSDatePickerTypes}` =
    DSDatePickerTypes.SINGLE as TType;

  public days: IDSDatePickerItem[] = [];

  public get previousMonth(): Date {
    return new Date(this.date.getFullYear(), this.date.getMonth() - 1);
  }

  public get nextMonth(): Date {
    return new Date(this.date.getFullYear(), this.date.getMonth() + 1);
  }
  public constructor(protected readonly _cdr: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['date'] &&
      isEqual(changes['date'].previousValue, changes['date'].currentValue)
    ) {
      return;
    }
    this.checkDays();
  }

  public checkDays(): void {
    this.days = [
      ...this.getListDaysInPreviousMonth(),
      ...this.getListDaysInCurrentMonth(),
      ...this.getListDaysInNextMonth(),
    ];
    this._cdr.markForCheck();
  }

  public selectDate(day: IDSDatePickerItem): void {
    if (day.disabled) {
      return;
    }

    this.selectedDate.emit(day.value);
  }

  protected getDaysInCurrentMonth(): number {
    return getDaysInMonth(this.date);
  }

  protected getDaysInPreviousMonth(): number {
    return getDaysInMonth(this.previousMonth);
  }

  protected getDaysInNextMonth(): number {
    return getDaysInMonth(this.nextMonth);
  }

  protected getListDaysInCurrentMonth(): IDSDatePickerItem[] {
    return Array.from({ length: this.getDaysInCurrentMonth() })
      .map(
        (_, i) => new Date(this.date.getFullYear(), this.date.getMonth(), i + 1)
      )
      .map((value) => ({
        value,
        text: value.getDate().toString(),
        customClasses: '',
        disabled: this.checkDisabled(value),
      }));
  }

  protected getListDaysInPreviousMonth(): IDSDatePickerItem[] {
    const days = Array.from({ length: this.getDaysInPreviousMonth() }).map(
      (_, i) =>
        new Date(
          this.previousMonth.getFullYear(),
          this.previousMonth.getMonth(),
          i + 1
        )
    );

    return days
      .splice(days.length - this.getCurrentFirstDay(), days.length)
      .map((value) => ({
        value,
        text: value.getDate().toString(),
        customClasses: 'not-in-month',
        disabled: true,
      }));
  }

  protected getListDaysInNextMonth(): IDSDatePickerItem[] {
    const days = Array.from({ length: this.getDaysInNextMonth() }).map(
      (_, i) =>
        new Date(this.nextMonth.getFullYear(), this.nextMonth.getMonth(), i + 1)
    );

    return days.splice(0, 6 - this.getCurrentLastDay()).map((value) => ({
      value,
      text: value.getDate().toString(),
      customClasses: 'not-in-month',
      disabled: true,
    }));
  }

  protected getCurrentFirstDay(): number {
    const firstDayPosition = getDay(
      new Date(this.date.getFullYear(), this.date.getMonth(), 1)
    );

    if (firstDayPosition === 0) {
      return 6;
    }

    return firstDayPosition - 1;
  }

  protected getCurrentLastDay(): number {
    const lastDayPosition = getDay(
      new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.getDaysInCurrentMonth()
      )
    );
    if (lastDayPosition === 0) {
      return 6;
    }

    return lastDayPosition - 1;
  }

  protected checkDisabled(value: Date): boolean {
    if (!this.min && !this.max) {
      return false;
    }

    if (this.min && this.max) {
      const compareStart = compareDesc(this.min, value);
      const compareEnd = compareDesc(value, this.max);

      return compareStart < 0 || compareEnd < 0;
    }

    if (this.min) {
      const compare = compareDesc(this.min, value);

      return compare < 0;
    }

    if (this.max) {
      const compare = compareDesc(value, this.max);

      return compare < 0;
    }

    return false;
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { compareAsc, compareDesc } from 'date-fns';

@Component({
  selector: 'm-ocloud-ds-date-picker-navigation',
  templateUrl: './date-picker-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '"contents"',
  },
})
export class DSDatePickerNavigationComponent {
  @Output()
  public readonly valueChange = new EventEmitter<Date>();

  @Input()
  public value: Date = new Date();

  @Input()
  public min: Date | null = null;

  @Input()
  public max: Date | null = null;

  public get disabledNext(): boolean {
    if (!this.max) {
      return false;
    }

    return (
      compareDesc(
        new Date(this.value.getFullYear(), this.value.getMonth(), 1),
        new Date(this.max.getFullYear(), this.max.getMonth() - 1, 1)
      ) <= 0
    );
  }

  public get disabledPrevious(): boolean {
    if (!this.min) {
      return false;
    }

    return (
      compareAsc(
        new Date(this.value.getFullYear(), this.value.getMonth(), 1),
        new Date(this.min.getFullYear(), this.min.getMonth() + 1, 1)
      ) <= 0
    );
  }

  public constructor(protected readonly _cdr: ChangeDetectorRef) {}

  public previous(): void {
    if (this.disabledPrevious) {
      return;
    }

    this.value = new Date(
      this.value.getFullYear(),
      this.value.getMonth() - 1,
      1
    );
    this.valueChange.emit(this.value);
    this._cdr.markForCheck();
  }

  public next(): void {
    if (this.disabledNext) {
      return;
    }

    this.value = new Date(
      this.value.getFullYear(),
      this.value.getMonth() + 1,
      1
    );
    this.valueChange.emit(this.value);
    this._cdr.markForCheck();
  }
}

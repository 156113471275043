import { createReducer, on } from '@ngrx/store';
import { ProfileActions } from './profile.actions';
import { IProfileState } from './profile.state';

//#region Properties

export const profileStoreKey = 'profileStoreKey';
const profileState: IProfileState = {
  profile: undefined,
};

export const profileReducer = createReducer(
  profileState,
  on(ProfileActions.saveProfile, (state, action) => ({
    ...state,
    profile: action.profile,
  }))
);

//#endregion

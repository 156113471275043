<button
  #selectDropdown="mOcloudDSDropdown"
  [cdkMenuTriggerFor]="itemsTemplate"
  [cdkMenuPosition]="positions"
  mOcloudDSDropdown
  class="ds-select"
  [class.disabled]="disabled"
  [class.read-only]="readOnly"
  [class.has-error]="hasError"
  [class.focused]="isOpen"
  [offsetY]="offsetY"
  [offsetX]="offsetX"
  [fixedWidth]="fixedWidth"
  (cdkMenuOpened)="onTouchedCallback(); isOpen = true"
  (cdkMenuClosed)="onTouchedCallback(); isOpen = false"
>
  <m-ocloud-ds-select-box
    [isPlaceholder]="isPlaceholder"
    [placeHolder]="placeHolder"
    [text]="renderTemplate"
    [animate]="isOpen"
  >
    <ng-container *ngIf="prefixTemplate?.templateRef as templateRef">
      <ng-template mOcloudDSSelectPrefix>
        <ng-container *ngTemplateOutlet="templateRef"></ng-container>
      </ng-template>
    </ng-container>
  </m-ocloud-ds-select-box>
</button>
<ng-template #itemsTemplate>
  <m-ocloud-ds-select-container
    [customClasses]="customClasses"
    [maxHeight]="maxHeight"
    [selectedValue]="value$ | async"
    [data]="listItemTemplates"
    [filterKey]="filterKey"
    [filterPlaceHolder]="filterPlaceHolder"
    [filterBy]="filterBy"
    [filterMode]="filterMode"
    (filterKeyChange)="filterKeyChange.emit($event)"
    (itemClick)="
      !$event.disabled && selectItem($event.value);
      !$event.disabled && closeOnSelected && selectDropdown.close(true)
    "
  ></m-ocloud-ds-select-container>
</ng-template>

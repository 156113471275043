import { Pipe, PipeTransform } from '@angular/core';
import { DSSelectFilter, IDSSelectItem } from '../interfaces';
import { DSSelectFilterBys } from '../enums';

@Pipe({
  name: 'mOcloudDSSelectFilter',
})
export class DSSelectFilterPipe implements PipeTransform {
  public transform<TType extends any>(
    data: IDSSelectItem<TType>[],
    filter: DSSelectFilter
  ): IDSSelectItem<TType>[] {
    const { filterBy, filterMode, filterKey } = filter;
    if (!filterMode || filterBy === DSSelectFilterBys.OUTSIDE) {
      return data;
    }
    return data.filter((item) =>
      item.text.toLowerCase().includes(filterKey.toLowerCase())
    );
  }
}

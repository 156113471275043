import {createAction, props} from '@ngrx/store';
import {IPatient} from '@main-data-access-interfaces';

const prefix = '[PATIENT_DETAIL]';

export class PatientDetailActions {

  //#region Properties

  public static readonly setPatient = createAction(`${prefix} Set patient detail`, props<{patient: IPatient}>());

  //#endregion

}

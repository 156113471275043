import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IDSSelectItem } from '../../interfaces';

@Component({
  selector: 'm-ocloud-ds-select-item',
  templateUrl: './select-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '"contents"',
  },
})
export class DSSelectItemComponent<TType extends any> {
  @Input()
  public data: IDSSelectItem<TType> | null = null;

  @Input()
  public isSelected: boolean | null = null;
}

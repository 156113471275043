import { NgModule } from '@angular/core';
import { DSTableComponent } from './table.component';
import {
  DSTableColumnBodyDirective,
  DSTableColumnHeaderDirective,
  DSTableColumnFooterDirective,
  DSTableRowStickyDirective,
} from './directives';
import { DSTableColumnComponent, DSTableSortComponent } from './components';
import {
  AsyncPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgTemplateOutlet,
} from '@angular/common';
import { DSIconModule } from '../icon';

@NgModule({
  declarations: [
    DSTableComponent,
    DSTableColumnComponent,
    DSTableSortComponent,
    DSTableColumnBodyDirective,
    DSTableColumnHeaderDirective,
    DSTableColumnFooterDirective,
    DSTableRowStickyDirective,
  ],
  imports: [NgIf, NgForOf, NgTemplateOutlet, NgClass, AsyncPipe, DSIconModule],
  exports: [
    DSTableComponent,
    DSTableColumnComponent,
    DSTableSortComponent,
    DSTableColumnBodyDirective,
    DSTableColumnHeaderDirective,
    DSTableColumnFooterDirective,
    DSTableRowStickyDirective,
  ],
})
export class DSTableModule {}

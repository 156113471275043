import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import { DSDatePickerTypes } from './enums';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DSControlDirective, DSControlTypes } from '../control';
import { IDSDatePickerRange } from './interfaces';
import { BehaviorSubject } from 'rxjs';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { format, isValid } from 'date-fns';
import { DSDatePickerValue } from './types';

@Component({
  selector: 'm-ocloud-ds-date-picker',
  templateUrl: './date-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DSDatePickerComponent),
      multi: true,
    },
    {
      provide: DSControlDirective,
      useExisting: DSDatePickerComponent,
    },
  ],
  host: {
    '[class]': '"contents"',
  },
})
export class DSDatePickerComponent<
    TType extends DSDatePickerTypes | `${DSDatePickerTypes}`,
    TValue = DSDatePickerValue<TType>
  >
  extends DSControlDirective<TValue | null>
  implements ControlValueAccessor
{
  @Input()
  public type: TType | DSDatePickerTypes | `${DSDatePickerTypes}` =
    DSDatePickerTypes.SINGLE as TType;

  @Input()
  public value: TValue | null = null;

  @Input()
  public hasError = false;

  @Input()
  public disabled = false;

  @Input()
  public readOnly = false;

  @Input()
  public fixedWidth = false;

  @Input()
  public customClasses = '';

  @Input()
  public placeHolder = '';

  @Input()
  public format: string | null = null;

  @Input()
  public min: Date | null = null;

  @Input()
  public max: Date | null = null;

  @Input()
  public live: IDSDatePickerRange = {
    start: new Date(1900, 0, 1),
    end: new Date(2100, 0, 1),
  };

  @Input()
  public offsetY = 8;

  @Input()
  public offsetX = 0;

  @Input()
  public positions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 8,
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
      offsetX: 0,
    },
  ];

  public readonly value$ = new BehaviorSubject<TValue | null>(null);

  public readonly controlType = DSControlTypes.DATE_PICKER;

  public tempValue: TValue | null = null;

  public onTouchedCallback: () => void = () => {};

  public onChangeCallback: (value: TValue | null) => void = () => {};

  public isOpen = false;

  public get isDisabled(): boolean {
    return this.disabled;
  }

  public get isReadonly(): boolean {
    return this.readOnly;
  }

  public get formatTemplate(): string {
    return this.format || 'dd/MM/yyyy';
  }

  public get isPlaceholder(): boolean {
    return !this.tempValue;
  }

  public get singleValue(): Date | null {
    return this.tempValue as Date | null;
  }

  public get rangeValue(): IDSDatePickerRange | null {
    return this.tempValue as IDSDatePickerRange | null;
  }

  public get renderTemplate(): string {
    return this.type === DSDatePickerTypes.RANGE
      ? this.renderRangeTemplate
      : this.renderSingleTemplate;
  }

  public get renderRangeTemplate(): string {
    if (!this.rangeValue) {
      return (
        this.placeHolder || `${this.formatTemplate} - ${this.formatTemplate}`
      );
    }

    const { start, end } = this.rangeValue;
    const parseStart = new Date(start as Date | string);
    const parseEnd = new Date(end as Date | string);

    return `${
      isValid(parseStart)
        ? format(parseStart, this.formatTemplate)
        : 'Invalid Date'
    } - ${
      isValid(parseEnd) ? format(parseEnd, this.formatTemplate) : 'Invalid Date'
    }`;
  }

  public get renderSingleTemplate(): string {
    if (!this.singleValue) {
      return this.placeHolder || this.formatTemplate;
    }
    const parseValue = new Date(this.singleValue);

    return isValid(parseValue)
      ? format(parseValue, this.formatTemplate)
      : 'Invalid Date';
  }

  public constructor(protected readonly _cdr: ChangeDetectorRef) {
    super();
  }

  public registerOnChange(fn: (value: TValue | null) => void): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: TValue | null): void {
    this.value = value;
    this.tempValue = value;
    this.value$.next(value);
    this.onChangeCallback(value);
    this.onTouchedCallback();
    this._cdr.markForCheck();
  }

  public updateTempValue(value: TValue | null): void {
    this.tempValue = value;
    this._cdr.markForCheck();
  }
}

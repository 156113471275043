import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  forwardRef,
  Input,
  OnChanges,
  QueryList,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { autoDestroy, followDestroy } from '../../shared';
import { DSControlDirective, DSControlTypes } from '../control';
import { RADIO_GROUP_COMPONENT_TOKEN } from './tokens';
import { v4 } from 'uuid';
import { isEqual } from 'lodash-es';
import { DSRadioComponent } from './radio.component';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IDSRadioGroupComponent } from './interfaces';

@Component({
  selector: 'm-ocloud-ds-radio-group',
  templateUrl: './radio-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DSRadioGroupComponent),
      multi: true,
    },
    {
      provide: DSControlDirective,
      useExisting: DSRadioGroupComponent,
    },
    {
      provide: RADIO_GROUP_COMPONENT_TOKEN,
      useExisting: DSRadioGroupComponent,
    },
  ],
  host: {
    '[class]': '"contents"',
  },
})
@autoDestroy()
export class DSRadioGroupComponent<TType extends any>
  extends DSControlDirective<TType | null>
  implements
    ControlValueAccessor,
    AfterViewInit,
    OnChanges,
    IDSRadioGroupComponent<TType>
{
  @ContentChildren(DSRadioComponent)
  public readonly children: QueryList<DSRadioComponent<TType>> | null = null;

  @Input()
  public id = v4();

  @Input()
  public name = v4();

  @Input()
  public value: TType | null = null;

  @Input()
  public hasError = false;

  @Input()
  public disabled = false;

  @Input()
  public readOnly = false;

  public get isReadonly(): boolean {
    return this.readOnly;
  }
  public get isDisabled(): boolean {
    return this.disabled;
  }

  public controlType: DSControlTypes = DSControlTypes.RADIO;
  public _onTouched: () => void = () => {};
  public _onChange: (value: TType | null) => void = () => {};

  protected readonly _value$ = new BehaviorSubject<TType | null>(null);

  public ngOnChanges(): void {
    this.setup();
  }
  public ngAfterViewInit(): void {
    this.setup();
  }

  public registerOnChange(fn: (value: TType | null) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: TType | null): void {
    if (isEqual(value, this.value)) {
      return;
    }

    this.value = value;
    this._value$.next(value);
    this._onChange(value);
    this._onTouched();
  }

  public listenValueChange(cb: (value: TType | null) => void): void {
    this._value$
      .pipe(
        tap((value) => {
          cb(value);
        }),
        followDestroy(this)
      )
      .subscribe();
  }

  public setup(): void {
    if (!this.children) return;
    this.setupAttributes();
  }

  public setupAttributes(): void {
    this.children?.forEach((radio) => {
      radio.name = this.name;
      radio.disabled = this.disabled;
      radio.readOnly = this.readOnly;
      radio.hasError = this.hasError;
    });
  }
}

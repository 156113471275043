import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'm-ocloud-ds-date-picker-actions',
  templateUrl: './date-picker-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '"contents"',
  },
})
export class DSDatePickerActionsComponent {
  @Output()
  public readonly cancelClick = new EventEmitter();

  @Output()
  public readonly applyClick = new EventEmitter();

  @Input()
  public customClasses = '';
}

import { Inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { ILoginState } from './login.state';
import { WINDOW } from '@ui-tool/core';
import { pipe, switchMap, tap } from 'rxjs';
import {
  AUTHENTICATION_SERVICE,
  IAuthenticationService,
} from '@main-data-access-services';

@Injectable()
export class LoginStore extends ComponentStore<ILoginState> {
  //#region Properties

  //#endregion

  //#region Constructor

  public constructor(
    @Inject(WINDOW)
    protected readonly _window: Window,
    @Inject(AUTHENTICATION_SERVICE)
    protected readonly _authenticationService: IAuthenticationService
  ) {
    super({});
  }

  //#endregion

  //#region Methods

  public login = this.effect<string>(
    pipe(
      switchMap((authenticationProvider) => {
        return this._authenticationService
          .getLoginPageUrlAsync(authenticationProvider)
          .pipe(
            tap((loginUri) => {
              if (loginUri) {
                this._window.location.href = loginUri;
                return;
              }
            })
          );
      })
    )
  );

  //#endregion
}

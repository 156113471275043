import { NgModule } from '@angular/core';
import { AppSettingsService } from './app-settings.service';
import { APP_SETTINGS_SERVICE } from './app-settings-service.constants';

@NgModule({
  providers: [
    {
      provide: APP_SETTINGS_SERVICE,
      useClass: AppSettingsService,
    },
  ],
})
export class AppSettingsServiceModule {}

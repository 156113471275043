import { Inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  ISmartNavigatorService,
  ISpinnerService,
  SMART_NAVIGATOR_SERVICE,
  SPINNER_SERVICE,
  WINDOW,
} from '@ui-tool/core';
import {finalize, mergeMap, of, pipe, switchMap} from 'rxjs';
import {
  AUTHENTICATION_SERVICE,
  IAuthenticationService,
} from '@main-data-access-services';
import { ICoreState } from './core.state';
import { SpinnerContainerIds } from '@main-data-access-enums';
import {LoginPageNavigationRequest} from '@main-data-access-models';

@Injectable()
export class CoreStore extends ComponentStore<ICoreState> {
  //#region Properties

  //#endregion

  //#region Constructor

  public constructor(
    @Inject(WINDOW)
    protected readonly _window: Window,
    @Inject(AUTHENTICATION_SERVICE)
    protected readonly _authenticationService: IAuthenticationService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    @Inject(SPINNER_SERVICE)
    protected readonly _spinnerService: ISpinnerService
  ) {
    super({});
  }

  //#endregion

  //#region Methods

  public signOut = this.effect<never>(
    pipe(
      switchMap(() => {
        const displaySpinnerRequest = this._spinnerService.displaySpinner(
          SpinnerContainerIds.MASTER_LAYOUT
        );
        return this._authenticationService.deleteAccessTokenAsync().pipe(
          mergeMap(() => {
            return this._navigationService.navigateToScreenAsync(new LoginPageNavigationRequest());
          }),
          finalize(() => this._spinnerService.deleteSpinner(SpinnerContainerIds.MASTER_LAYOUT, displaySpinnerRequest))
        );
      })
    )
  );

  //#endregion
}

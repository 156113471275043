<ng-container *ngIf="control">
  <div
    class="ds-field ds-field--{{ size }} {{ customClasses }}"
    [style.--ds-field-width]="defaultWidth"
  >
    <header *ngIf="label">
      <label>{{ label }}</label>
      <span *ngIf="required"> *</span>
    </header>
    <main>
      <ng-container *ngIf="controlPrefix?.templateRef as prefixTemplateRef">
        <ng-container *ngTemplateOutlet="prefixTemplateRef"></ng-container>
      </ng-container>
      <ng-content></ng-content>
      <ng-container *ngIf="controlSuffix?.templateRef as suffixTemplateRef">
        <ng-container *ngTemplateOutlet="suffixTemplateRef"></ng-container>
      </ng-container>
    </main>
    <footer *ngIf="hintMessage || (showError && errorMessage)">
      <span>{{ showError ? errorMessage : hintMessage }}</span>
    </footer>
  </div>
</ng-container>

import { NgModule } from '@angular/core';
import { DSAvatarComponent } from './avatar.component';
import { NgIf } from '@angular/common';
import { DSIconModule } from '../icon';

@NgModule({
  declarations: [DSAvatarComponent],
  imports: [NgIf, DSIconModule],
  exports: [DSAvatarComponent],
})
export class DSAvatarModule {}

import { NgModule } from '@angular/core';
import { DSAlertComponent } from './alert.component';
import { DSButtonModule } from '../../common/button';
import { DSIconModule } from '../../common/icon';
import { InlineSVGModule } from 'ng-inline-svg-2';
import {
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgTemplateOutlet,
} from '@angular/common';
import { DS_ALERT_SERVICE_TOKEN } from './constants';
import { DSAlertService } from './alert.service';
import { DSAlertHostComponent } from './alert-host.component';
import { DSApplicationServiceModule } from '../../shared';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  declarations: [DSAlertComponent, DSAlertHostComponent],
  imports: [
    DSButtonModule,
    DSIconModule,
    InlineSVGModule,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    NgTemplateOutlet,
    DSApplicationServiceModule,
    OverlayModule,
  ],
  providers: [
    {
      provide: DS_ALERT_SERVICE_TOKEN,
      useClass: DSAlertService,
    },
  ],
  exports: [DSAlertComponent, DSAlertHostComponent],
})
export class DSAlertModule {}

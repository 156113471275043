import { fromEvent, merge, Observable } from 'rxjs';

export function anyParentEvent(
  ref: HTMLElement,
  event: string
): Observable<Event> {
  const elements = [ref];
  let element: HTMLElement | null = ref;
  while (element != null) {
    element = element.parentElement;
    if (element) {
      elements.push(element);
    }
  }
  return merge(...elements.map((el) => fromEvent(el, event)));
}

import { NgModule } from '@angular/core';
import { MedicationsService } from './medications.service';
import { PATIENT_MEDICATION_SERVICE } from './medications-service.constants';

@NgModule({
  providers: [
    {
      provide: PATIENT_MEDICATION_SERVICE,
      useClass: MedicationsService,
    },
  ],
})
export class MedicationsServiceModule {}

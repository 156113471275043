import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { IDSDatePickerRange } from '../../interfaces';
import { compareDesc, getDay, getDaysInMonth } from 'date-fns';
import { cloneDeep } from 'lodash-es';
import { DSDatePickerMenuTypes } from '../../enums';

@Component({
  selector: 'm-ocloud-ds-date-picker-range',
  templateUrl: './date-picker-range.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '"contents"',
  },
})
export class DSDatePickerRangeComponent implements OnChanges {
  @Output()
  public readonly cancelClick = new EventEmitter();

  @Output()
  public readonly applyClick = new EventEmitter();

  @Output()
  public readonly valueChange = new EventEmitter<IDSDatePickerRange | null>();

  @Input()
  public value: IDSDatePickerRange | null = null;

  @Input()
  public text = '';

  @Input()
  public isPlaceholder = false;

  @Input()
  public min: Date | null = null;

  @Input()
  public max: Date | null = null;

  @Input()
  public live: IDSDatePickerRange = {
    start: new Date(1900, 0, 1),
    end: new Date(2100, 0, 1),
  };

  public range: IDSDatePickerRange = {
    start: new Date(),
    end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
  };

  public get leftMin(): Date | null {
    const min = cloneDeep(this.min);
    min?.setMonth(min?.getMonth() - 1);
    return min;
  }

  public get leftMax(): Date | null {
    return this.range.end;
  }

  public get rightMin(): Date | null {
    return this.range.start;
  }

  public get rightMax(): Date | null {
    const max = cloneDeep(this.max);
    max?.setMonth(max?.getMonth() + 1);
    return max;
  }

  public defaultValue: IDSDatePickerRange | null = null;

  public constructor(protected readonly _cdr: ChangeDetectorRef) {}

  public ngOnChanges(): void {
    this.defaultValue = cloneDeep(this.value);
    this.checkRange();
  }

  public checkRange(): void {
    if (!this.value?.start || !this.value?.end) {
      return;
    }

    const { start, end } = this.value;

    if (
      compareDesc(
        new Date(start.getFullYear(), start.getMonth()),
        new Date(end.getFullYear(), end.getMonth())
      ) === 0
    ) {
      this.setRange(start, 'start');
      this.setRange(end, 'end', end.getMonth() + 1);
    } else {
      this.setRange(start, 'start');
      this.setRange(end, 'end');
    }
    this._cdr.markForCheck();
  }

  public selectDate(date: Date, position: 'end' | 'start'): void {
    if (!this.value) {
      this.value = {
        ...({} as IDSDatePickerRange),
        [position]: date,
      } as IDSDatePickerRange;
    }

    const { start, end } = this.value;

    if (start && end) {
      this.value = {
        ...({} as IDSDatePickerRange),
        [position]: date,
      } as IDSDatePickerRange;
    } else {
      if (start) {
        const compare = compareDesc(start, date);

        if (compare === 0) {
          return;
        }

        if (compare > 0) {
          this.value = {
            start,
            end: date,
          };
        } else {
          this.value = {
            start: date,
            end: start,
          };
        }
      } else {
        const compare = compareDesc(date, end);

        if (compare === 0) {
          return;
        }

        if (compare > 0) {
          this.value = {
            start: date,
            end,
          };
        } else {
          this.value = {
            start: end,
            end: date,
          };
        }
      }
    }

    this.checkToEmit();
    this._cdr.markForCheck();
  }

  public checkToEmit(): void {
    if (!this.value?.start || !this.value?.end) {
      return;
    }

    this.valueChange.emit(this.value);
  }

  public cancel(): void {
    this.value = cloneDeep(this.defaultValue);
    this.checkToEmit();
    this.cancelClick.emit();
  }

  public selectMenuItem(value: DSDatePickerMenuTypes): void {
    this.value = null;
    const current = new Date();
    const currentDay = getDay(current) > 0 ? getDay(current) - 1 : 6;
    switch (value) {
      case DSDatePickerMenuTypes.TODAY:
        this.selectDate(current, 'start');
        break;
      case DSDatePickerMenuTypes.YESTERDAY:
        this.selectDate(
          new Date(
            current.getFullYear(),
            current.getMonth(),
            current.getDate() - 1
          ),
          'start'
        );
        break;
      case DSDatePickerMenuTypes.THIS_WEEK:
        const startThisWeek = new Date();
        startThisWeek.setDate(startThisWeek.getDate() - currentDay);
        const endThisWeek = new Date();
        endThisWeek.setDate(endThisWeek.getDate() + (6 - currentDay));
        this.selectDate(startThisWeek, 'start');
        this.selectDate(endThisWeek, 'end');
        break;
      case DSDatePickerMenuTypes.LAST_WEEK:
        const lastWeek = new Date();
        lastWeek.setDate(lastWeek.getDate() - currentDay - 1);
        const currentDayOfLastWeek =
          getDay(lastWeek) > 0 ? getDay(lastWeek) - 1 : 6;
        const startLastWeek = cloneDeep(lastWeek);
        startLastWeek.setDate(startLastWeek.getDate() - currentDayOfLastWeek);
        const endLastWeek = cloneDeep(lastWeek);
        endLastWeek.setDate(endLastWeek.getDate() + (6 - currentDayOfLastWeek));
        this.selectDate(startLastWeek, 'start');
        this.selectDate(endLastWeek, 'end');
        break;
      case DSDatePickerMenuTypes.THIS_MONTH:
        this.selectDate(
          new Date(current.getFullYear(), current.getMonth(), 1),
          'start'
        );
        this.selectDate(
          new Date(
            current.getFullYear(),
            current.getMonth(),
            getDaysInMonth(current)
          ),
          'end'
        );
        break;
      case DSDatePickerMenuTypes.LAST_MONTH:
        const lastMonth = new Date(
          current.getFullYear(),
          current.getMonth() - 1,
          1
        );
        this.selectDate(new Date(lastMonth), 'start');
        this.selectDate(
          new Date(
            lastMonth.getFullYear(),
            lastMonth.getMonth(),
            getDaysInMonth(lastMonth)
          ),
          'end'
        );
        break;
      case DSDatePickerMenuTypes.THIS_YEAR:
        this.selectDate(new Date(current.getFullYear(), 0, 1), 'start');
        this.selectDate(
          new Date(
            current.getFullYear(),
            11,
            getDaysInMonth(new Date(current.getFullYear(), 11, 1))
          ),
          'end'
        );
        break;
      case DSDatePickerMenuTypes.LAST_YEAR:
        const lastYear = new Date(current.getFullYear() - 1, 0, 1);
        this.selectDate(new Date(lastYear.getFullYear(), 0, 1), 'start');
        this.selectDate(
          new Date(
            lastYear.getFullYear(),
            11,
            getDaysInMonth(new Date(lastYear.getFullYear(), 11, 1))
          ),
          'end'
        );
        break;
      case DSDatePickerMenuTypes.ALL_TIME:
        this.selectDate(new Date(1900, 0, 1), 'start');
        this.selectDate(new Date(2100, 0, 1), 'end');
        break;
    }
    this._cdr.markForCheck();
    this.checkToEmit();
  }

  protected setRange(
    value: Date,
    position: 'end' | 'start',
    customMonth?: number
  ): void {
    this.range[position] = new Date(
      value.getFullYear(),
      customMonth || value.getMonth(),
      1
    );
  }
}

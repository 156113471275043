import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
} from '@angular/core';
import { DSFieldSizes } from './enums';
import {
  DSFieldControlPrefixDirective,
  DSFieldControlSuffixDirective,
} from './directives';
import { DSControlDirective } from '../control';

@Component({
  selector: 'm-ocloud-ds-field',
  templateUrl: './field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '"contents"',
  },
})
export class DSFieldComponent {
  @ContentChild(DSFieldControlPrefixDirective)
  public readonly controlPrefix: DSFieldControlPrefixDirective | null = null;

  @ContentChild(DSFieldControlSuffixDirective)
  public readonly controlSuffix: DSFieldControlSuffixDirective | null = null;

  @ContentChild(DSControlDirective)
  public readonly control: DSControlDirective | null = null;

  @Input()
  public size: DSFieldSizes | `${DSFieldSizes}` = DSFieldSizes.SM;

  @Input()
  public label: string | null = null;

  @Input()
  public hintMessage: string | null = null;

  @Input()
  public errorMessage: string | null = null;

  @Input()
  public showError = false;

  @Input()
  public required = false;

  @Input()
  public customClasses = '';

  @Input()
  public defaultWidth = '';
}

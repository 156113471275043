import {createAction, props} from '@ngrx/store';
import {IAppointment} from '@main-data-access-interfaces';

const prefix = '[APPOINTMENT_DETAIL]';

export class AppointmentDetailActions {

  //#region Properties

  public static readonly setAppointment = createAction(`${prefix} Set appointment detail`, props<{ appointment: IAppointment }>());

  //#endregion

}

import { Inject, Injectable } from '@angular/core';
import { IStatisticsService } from './statistics-service.interface';
import { mergeMap, Observable } from 'rxjs';
import {
  IGetPatientStatistic,
  IStatisticsReport,
} from '@main-data-access-interfaces';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@main-data-access-resolvers';
import { SERVICE__DOWNLOAD } from '../../shared/download/download-service.constants';
import { IDownloadService } from '../../shared/download/download-service.interface';

@Injectable()
export class StatisticsService implements IStatisticsService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endpointResolver: IApiEndpointResolver,
    @Inject(SERVICE__DOWNLOAD)
    protected readonly _downloadService: IDownloadService,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion Constructor

  //#region Methods

  public getReportsAsync(
    params: IGetPatientStatistic
  ): Observable<IStatisticsReport[]> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        let httpParams = new HttpParams();
        if (params.fromDate) {
          httpParams = httpParams.append('fromDate', params.fromDate);
        }

        if (params.toDate) {
          httpParams = httpParams.append('toDate', params.toDate);
        }
        const apiUrl = `${baseUrl}/statistics/patients/daily-login`;
        return this._httpClient.get<IStatisticsReport[]>(apiUrl, {
          params: httpParams,
        });
      })
    );
  }

  public downloadReportAsync(params: IGetPatientStatistic): Observable<void> {
    let httpParams = new HttpParams();
    if (params.fromDate) {
      httpParams = httpParams.append('fromDate', params.fromDate);
    }

    if (params.toDate) {
      httpParams = httpParams.append('toDate', params.toDate);
    }

    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/statistics/patients/daily-login/export`;
        const httpRequest = new HttpRequest('GET', apiUrl, null, {
          params: httpParams,
        });
        return this._downloadService.downloadAsync(httpRequest);
      })
    );
  }

  //#endregion Methods
}

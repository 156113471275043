import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  Optional,
  Output,
  TemplateRef,
} from '@angular/core';
import { DSAlertColors, DSAlertFooterTypes, DSAlertSizes } from './enums';
import { DSButtonAppearances } from '../../common/button';
import { IDSAlertComponent, IDSAlertServiceConfig } from './interfaces';
import { fadeIn } from '../../shared';
import { DS_ALERT_SERVICE_CONFIG_TOKEN } from './constants';

@Component({
  selector: 'm-ocloud-ds-alert',
  templateUrl: './alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn],
  host: {
    '[class]': '"contents"',
  },
})
export class DSAlertComponent<TOutput, TContent, TData>
  implements IDSAlertComponent
{
  @Output()
  public readonly closeClick = new EventEmitter<TOutput>();

  @Output()
  public readonly dismissClick = new EventEmitter<TOutput>();

  @Output()
  public readonly changeClick = new EventEmitter<TOutput>();

  @Output()
  public readonly opened = new EventEmitter();

  @Output()
  public readonly closed = new EventEmitter();

  @Input()
  public size: DSAlertSizes | `${DSAlertSizes}` = DSAlertSizes.FLOATING;

  @Input()
  public color: DSAlertColors | `${DSAlertColors}` = DSAlertColors.DEFAULT;

  @Input()
  public dismissLabel = 'Dismiss';

  @Input()
  public dismissButtonAppearance:
    | DSButtonAppearances
    | `${DSButtonAppearances}` = DSButtonAppearances.SECONDARY_GRAY;

  @Input()
  public changeLabel = 'View Changes';

  @Input()
  public changeButtonAppearance:
    | DSButtonAppearances
    | `${DSButtonAppearances}` = DSButtonAppearances.PRIMARY;

  @Input()
  public title: string | TemplateRef<HTMLElement> = '';

  @Input()
  public description: string | TemplateRef<HTMLElement> = '';

  @Input()
  public iconTemplate: TemplateRef<HTMLElement> | null = null;

  @Input()
  public footerType: DSAlertFooterTypes | `${DSAlertFooterTypes}` =
    DSAlertFooterTypes.LINK;

  @Input()
  public closeOnChangeClick = false;

  @Input()
  public closeOnDismissClick = false;

  @HostBinding('@fadeIn')
  public get fadeIn() {
    return this.animationStatus;
  }

  public get colorIconLink(): string {
    return `design-system/assets/svgs/alert/${this.color}.svg`;
  }

  public animationStatus: 'enter' | 'leave' = 'enter';

  public readonly through: 'component' | 'snack' = 'component';

  public constructor(
    @Optional()
    @Inject(DS_ALERT_SERVICE_CONFIG_TOKEN)
    private readonly _config: IDSAlertServiceConfig<TData, TContent>
  ) {
    if (this._config) {
      this.through = 'snack';
      this._setupSnack();
    }
  }

  public change(): void {
    this.changeClick.emit();
    if (this.closeOnChangeClick) {
      this.close();
    }
  }

  public dismiss(): void {
    this.dismissClick.emit();
    if (this.closeOnDismissClick) {
      this.close();
    }
  }

  public close(): void {
    this.closeClick.emit();
  }

  @HostListener('@fadeIn.done')
  public animationDone(): void {
    if (this.animationStatus === 'leave') {
      this.closed.emit();
    } else {
      this.opened.emit();
    }
  }

  public isTemplate(value: string | TemplateRef<HTMLElement>): boolean {
    return value instanceof TemplateRef;
  }

  private _setupSnack(): void {
    this.title = this._config.option.title;
    this.description = this._config.option.description;
    this.iconTemplate = this._config.option.iconTemplate;
    this.footerType = this._config.option.footerType;
    this.size = this._config.option.size;
    this.color = this._config.option.color;
    this.dismissLabel = this._config.option.dismissLabel;
    this.dismissButtonAppearance = this._config.option.dismissButtonAppearance;
    this.changeLabel = this._config.option.changeLabel;
    this.changeButtonAppearance = this._config.option.dismissButtonAppearance;
  }
}

import { NgModule } from '@angular/core';
import { SERVICE__DOWNLOAD } from './download-service.constants';
import { DownloadService } from './download.service';

@NgModule({
  providers: [
    {
      provide: SERVICE__DOWNLOAD,
      useClass: DownloadService,
    },
  ],
})
export class DownloadServiceModule {}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { profileStoreKey } from './profile.reducer';
import { IProfileState } from './profile.state';

const getProfile = createFeatureSelector<IProfileState>(profileStoreKey);

export class ProfileSelectors {
  //#region Properties

  public static readonly profile = createSelector(getProfile, (x) => x.profile);

  //#endregion
}

export class PatientDetailRouteParams {

  //#region Constructor

  public constructor(public readonly mrn: string) {
  }

  //#endregion

}

import {NavigateToScreenRequest} from '@ui-tool/core';
import {ScreenCodes} from '@main-data-access-enums';

export class DashboardPageNavigationRequest extends NavigateToScreenRequest<void> {

  //#region Constructor

  public constructor() {
    super(ScreenCodes.DASHBOARD);
  }

  //#endregion

}

import { NgModule } from '@angular/core';
import { DSModalComponent } from './modal.component';
import { DSModalBodyDirective, DSModalFooterDirective } from './directives';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { DSIconModule } from '../../common/icon';
import { DSButtonModule } from '../../common/button';
import { InlineSVGModule } from 'ng-inline-svg-2';

@NgModule({
  declarations: [
    DSModalComponent,
    DSModalBodyDirective,
    DSModalFooterDirective,
  ],
  exports: [DSModalComponent, DSModalBodyDirective, DSModalFooterDirective],
  imports: [
    NgIf,
    NgTemplateOutlet,
    DSIconModule,
    DSButtonModule,
    InlineSVGModule,
  ],
})
export class DSModalModule {}

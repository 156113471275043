import { Inject, Injectable } from '@angular/core';
import { IAuthenticationService } from './authentication-service.interface';
import { map, mergeMap, Observable, of, throwError } from 'rxjs';
import {
  APP_SETTINGS_SERVICE,
  IAppSettingsService,
} from '@main-data-access-services';
import { LocalForageService } from 'ngx-localforage';
import { ExceptionCodes, StorageKeys } from '@main-data-access-enums';
import { IProfile } from '@main-data-access-interfaces';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@main-data-access-resolvers';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthenticationService implements IAuthenticationService {
  //#region Constructor

  public constructor(
    @Inject(APP_SETTINGS_SERVICE)
    protected readonly _appSettingService: IAppSettingsService,
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _apiEndpointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient,
    protected readonly _storage: LocalForageService
  ) {}

  //#endregion

  //#region Methods

  public getLoginPageUrlAsync(
    authenticationProvider: string
  ): Observable<string | undefined> {
    return this._appSettingService.loadSettingsAsync().pipe(
      map((appSettings) => {
        const openAuthenticationOptions =
          appSettings.openAuthenticationOptions || [];
        const option = openAuthenticationOptions.find(
          (x) => x.provider === authenticationProvider
        );
        if (!option) {
          return undefined;
        }

        const queryStrings = new URLSearchParams();
        queryStrings.set('client_id', option!.clientId);
        queryStrings.set('response_type', option.responseType);
        queryStrings.set(
          'redirect_uri',
          `${option!.redirectUrl}?provider=${authenticationProvider}`
        );
        queryStrings.set('scope', option!.scope);
        queryStrings.set('nonce', this.generateRandomString(8));
        queryStrings.set('prompt', 'select_account');
        return `${option!.baseUrl}/oauth2/v2.0/authorize?${decodeURIComponent(
          queryStrings.toString()
        )}`;
      })
    );
  }

  private generateRandomString(length: number): string {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }

    return result;
  }

  public saveAccessTokenAsync(accessToken: string): Observable<void> {
    return this._storage.setItem(StorageKeys.ACCESS_TOKEN, accessToken);
  }

  public deleteAccessTokenAsync(): Observable<void> {
    return this._storage.removeItem(StorageKeys.ACCESS_TOKEN);
  }

  public getAccessTokenAsync(): Observable<string> {
    return this._storage.getItem(StorageKeys.ACCESS_TOKEN).pipe(
      mergeMap((accessToken) => {
        if (!accessToken) {
          return throwError(() => ExceptionCodes.ACCESS_TOKEN_NOT_FOUND);
        }
        return this.__validateAccessTokenAsync(accessToken).pipe(
          map(() => accessToken)
        );
      })
    );
  }

  public getProfileAsync(): Observable<IProfile> {
    return this._apiEndpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/users/details`;
        return this._httpClient.get<any>(apiUrl).pipe(
          map(({ user, features }) => {
            return {
              ...user,
              features,
            };
          })
        );
      })
    );
  }

  //#endregion

  //#region Internal methods

  protected __validateAccessTokenAsync(accessToken: string): Observable<void> {
    // TODO: Token validation logic will be defined here.
    return of(void 0);
  }

  //#endregion
}

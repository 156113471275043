import { NgModule } from '@angular/core';
import { STATISTICS_SERVICE } from './statistics-service.constants';
import { StatisticsService } from './statistics.service';
import { DownloadServiceModule } from '../../shared/download/download-service.module';

@NgModule({
  imports: [DownloadServiceModule],
  providers: [
    {
      provide: STATISTICS_SERVICE,
      useClass: StatisticsService,
    },
  ],
})
export class StatisticsServiceModule {}

import { InjectionToken } from "@angular/core";
import { IDiagnosisService } from "./diagnosis-service.interface";
import { IDiagnosis } from "@main-data-access-interfaces";

export const DIAGNOSIS_SERVICE = new InjectionToken<IDiagnosisService>('DIAGNOSIS_SERVICE');

export const DIAGNOSIS_MOCK: IDiagnosis[] = Array
  .from({length: 1000})
  .map((e, i) => ({
    id: `${i + 1}`,
    status: 'NEW',
    code: `Code-${i + 1}`,
    codeType: `NEW`,
  }));

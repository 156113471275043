import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store } from "@ngrx/store";
import { IRootState, LayoutActions } from "@main-data-access-stores";

@Injectable()
export class BreadcrumbResolver implements Resolve<string> {

  public constructor(
    private readonly _store: Store<IRootState>
  ) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> | Promise<string> {
    this._store.dispatch(LayoutActions.setBreadcrumb({data: route.data['title']}))
    return of(route.data['title']);
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DSDatePickerTypes } from '../../enums';

@Component({
  selector: 'm-ocloud-ds-date-picker-value',
  templateUrl: './date-picker-value.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '"contents"',
  },
})
export class DSDatePickerValueComponent {
  @Output()
  public readonly todayClick = new EventEmitter();

  @Input()
  public text = '';

  @Input()
  public isPlaceholder = false;

  @Input()
  public type: DSDatePickerTypes | `${DSDatePickerTypes}` =
    DSDatePickerTypes.SINGLE;

  public get rangeText(): [string, string] {
    return this.text.split(' - ') as [string, string];
  }
}

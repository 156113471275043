import { NgModule } from '@angular/core';
import { DSPaginationPrimaryComponent } from './pagination-primary.component';
import { DSButtonModule } from '../../button';
import { DSIconModule } from '../../icon';

@NgModule({
  declarations: [DSPaginationPrimaryComponent],
  imports: [DSButtonModule, DSIconModule],
  exports: [DSPaginationPrimaryComponent],
})
export class DSPaginationPrimaryModule {}

import { NgModule } from "@angular/core";
import { TREATMENT_SERVICE } from "./treatment-service.constants";
import { TreatmentService } from "./treatment.service";

@NgModule({
  providers: [
    {
      provide: TREATMENT_SERVICE,
      useClass: TreatmentService
    }
  ]
})
export class TreatmentServiceModule { }

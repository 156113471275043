import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DSPaginationDirective } from '../pagination.directive';

@Component({
  selector: 'm-ocloud-ds-pagination-group',
  templateUrl: './pagination-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '"contents"',
  },
})
export class DSPaginationGroupComponent extends DSPaginationDirective {
  public get text(): string {
    return `Page ${this.index + 1} of ${this.pages}`;
  }

  public get items(): (number | '...')[] {
    if (this.pages <= 6) {
      return Array.from({ length: this.pages }).map((_, i) => i);
    }

    return [
      0,
      this.index > 2 ? '...' : 1,
      this.index < this.pages - 4
        ? this.index > 2
          ? this.index
          : 2
        : this.pages - 4,
      this.index < this.pages - 4 ? '...' : this.pages - 3,
      this.pages - 2,
      this.pages - 1,
    ];
  }
}

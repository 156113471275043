import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { ILayoutStoreState } from "./layout.state";
import { Themes } from "@main-data-access-enums";

export const LAYOUT_THEME = 'LAYOUT_THEME';

export const layoutAdapter: EntityAdapter<never> =
  createEntityAdapter<never>();

export const layoutStoreInitialState: ILayoutStoreState =
  layoutAdapter.getInitialState({
    theme: Themes.LIGHT,
    showDrawer: false,
    breadcrumb: 'Dashboard'
  });

import { Pipe, PipeTransform } from '@angular/core';
import { DSDatePickerTypes } from '../enums';
import { IDSDatePickerRange } from '../interfaces';
import { DSDatePickerValue } from '../types';
import { compareDesc } from 'date-fns';
import { toBasicDate } from '../constants';

@Pipe({
  name: 'mOcloudDSDatePickerActive',
})
export class DSDatePickerActivePipe<
  TType extends DSDatePickerTypes | `${DSDatePickerTypes}`,
  TValue = DSDatePickerValue<TType>
> implements PipeTransform
{
  public transform(
    value: Date,
    type: TType | DSDatePickerTypes | `${DSDatePickerTypes}`,
    current: TValue | null
  ): string {
    const singleValue = current as Date | null;
    const rangeValue = current as IDSDatePickerRange | null;
    if (type === DSDatePickerTypes.SINGLE) {
      return this.resolveSingleValue(value, singleValue);
    } else {
      return this.resolveRangeValue(value, rangeValue);
    }
  }

  protected resolveSingleValue(value: Date, data: Date | null): string {
    if (!data) {
      return '';
    }

    return compareDesc(toBasicDate(data), toBasicDate(value)) === 0
      ? 'active'
      : '';
  }

  protected resolveRangeValue(
    value: Date,
    data: IDSDatePickerRange | null
  ): string {
    if (!data) {
      return '';
    }

    const { start, end } = data;

    if (start && end) {
      const compareStart = compareDesc(toBasicDate(start), toBasicDate(value));
      const compareEnd = compareDesc(toBasicDate(value), toBasicDate(end));

      if (compareStart === 0) {
        return 'range active first';
      }

      if (compareEnd === 0) {
        return 'range active last';
      }

      if (compareStart > 0 && compareEnd > 0) {
        return 'range';
      }

      return '';
    } else {
      if (start) {
        return compareDesc(toBasicDate(start), toBasicDate(value)) === 0
          ? 'active'
          : '';
      }

      if (end) {
        return compareDesc(toBasicDate(value), toBasicDate(end)) === 0
          ? 'active'
          : '';
      }

      return '';
    }
  }
}

import { NgModule } from '@angular/core';
import { DSPaginationFullPageComponent } from './pagination-full-page.component';
import { DSButtonGroupModule } from '../../button-group';
import { AsyncPipe, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { DSSelectModule } from '../../../form';
import { DSIconModule } from '../../icon';
import { CdkMenuModule } from '@angular/cdk/menu';
import { DSDropdownModule } from '../../../cdk';

@NgModule({
  declarations: [DSPaginationFullPageComponent],
  exports: [DSPaginationFullPageComponent],
  imports: [
    DSButtonGroupModule,
    NgForOf,
    DSSelectModule,
    DSIconModule,
    CdkMenuModule,
    DSDropdownModule,
    AsyncPipe,
    NgIf,
    NgTemplateOutlet,
  ],
})
export class DSPaginationFullPageModule {}

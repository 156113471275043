import { animate, style, transition, trigger } from '@angular/animations';

const TRANSITION = `{{duration}}ms ease-in-out`;
const DURATION = { params: { duration: 300 } };

export const fadeIn = trigger('fadeIn', [
  transition(
    ':enter',
    [
      style({
        transform: 'translateY(-10px)',
        opacity: 0,
      }),
      animate(
        TRANSITION,
        style({
          transform: 'translateY(0)',
          opacity: 1,
        })
      ),
    ],
    DURATION
  ),
  transition(
    ':leave',
    [
      style({
        transform: 'translateY(0)',
        opacity: 1,
      }),
      animate(
        TRANSITION,
        style({
          transform: 'translateY(-10px)',
          opacity: 0,
        })
      ),
    ],
    DURATION
  ),
]);

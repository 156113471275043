import { InjectionToken } from "@angular/core";
import { IDrugService } from "./drug-service.interface";
import { IDrug } from "@main-data-access-interfaces";

export const DRUG_SERVICE = new InjectionToken<IDrugService>('DRUG_SERVICE');

export const DRUG_MOCK: IDrug[] = Array
  .from({length: 1000})
  .map((e, i) => ({
    id: `${i + 1}`,
    code: `Code-${i + 1}`,
    name: `Name-${i + 1}`,
    ordered: `Ordered-${i + 1}`,
    dispensed: `Dispensed-${i + 1}`,
  }));

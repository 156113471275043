import { NgModule } from '@angular/core';
import { SymptomsService } from './symptoms.service';
import { SYMPTOMS_SERVICE } from './symptoms-service.constants';

@NgModule({
  providers: [
    {
      provide: SYMPTOMS_SERVICE,
      useClass: SymptomsService,
    },
  ],
})
export class SymptomsServiceModule {}

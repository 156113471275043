import { NgModule } from '@angular/core';
import { DSSelectComponent } from './select.component';
import {
  AsyncPipe,
  NgForOf,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgTemplateOutlet,
} from '@angular/common';
import { DSSelectItemDirective, DSSelectPrefixDirective } from './directives';
import { CdkMenuModule } from '@angular/cdk/menu';
import { DSDropdownModule } from '../../cdk/dropdown';
import { DSIconModule } from '../../common/icon';
import { DSCheckboxModule } from '../checkbox';
import { DSRadioModule } from '../radio';
import {
  DSSelectBoxComponent,
  DSSelectContainerComponent,
  DSSelectItemComponent,
} from './components';
import { DSFieldModule } from '../field';
import { DSInputModule } from '../input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DSSelectFilterPipe } from './pipes';

@NgModule({
  declarations: [
    DSSelectComponent,
    DSSelectPrefixDirective,
    DSSelectItemDirective,
    DSSelectItemComponent,
    DSSelectContainerComponent,
    DSSelectBoxComponent,
    DSSelectFilterPipe,
  ],
  exports: [
    DSSelectComponent,
    DSSelectPrefixDirective,
    DSSelectItemDirective,
    DSSelectItemComponent,
    DSSelectContainerComponent,
    DSSelectBoxComponent,
    DSSelectFilterPipe,
  ],
  imports: [
    NgIf,
    CdkMenuModule,
    DSDropdownModule,
    NgTemplateOutlet,
    DSIconModule,
    NgForOf,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    AsyncPipe,
    DSCheckboxModule,
    DSRadioModule,
    DSFieldModule,
    DSInputModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class DSSelectModule {}

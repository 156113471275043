import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
  StaticProvider,
  Type,
} from '@angular/core';
import { IDSApplicationService } from './application-service.interface';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class DSApplicationService implements IDSApplicationService {
  public constructor(
    @Inject(DOCUMENT)
    private readonly _document: Document,
    private readonly _injector: Injector,
    private readonly _appRef: ApplicationRef,
    private readonly _resolver: ComponentFactoryResolver
  ) {}

  public createComponentRef<TInjector extends Injector, TComponent = unknown>(
    component: Type<TComponent>,
    injector?: TInjector
  ): ComponentRef<TComponent> {
    const factoryResolver = this._resolver.resolveComponentFactory(component);

    const componentRef = factoryResolver.create(injector || this._injector);

    this.attachHostView(componentRef.hostView);

    return componentRef;
  }

  public createInjector(
    providers: StaticProvider[],
    parentInjector?: Injector
  ): Injector {
    return Injector.create({
      parent: parentInjector || this._injector,
      providers,
    });
  }

  public attachHostView<T>(hostView: ComponentRef<T>['hostView']): void {
    this._appRef.attachView(hostView);
  }

  public detachHostView<T>(hostView: ComponentRef<T>['hostView']): void {
    this._appRef.detachView(hostView);
  }

  public appendTo<
    TElement extends HTMLElement = HTMLElement,
    TParent extends HTMLElement = HTMLElement
  >(element: TElement, parent: TParent = this._document.body as TParent): void {
    parent.appendChild(element);
  }

  public removeFrom<
    TElement extends HTMLElement = HTMLElement,
    TParent extends HTMLElement = HTMLElement
  >(element: TElement, parent: TParent = this._document.body as TParent): void {
    parent.removeChild(element);
  }

  public getHostViewElement<T, R extends HTMLElement = HTMLElement>(
    hostView: ComponentRef<T>['hostView']
  ): R {
    const embeddedViewRef = hostView as EmbeddedViewRef<R>;

    return embeddedViewRef.rootNodes[0];
  }
}

<div class="ds-radio ds-radio--{{ size }} ds-radio--{{ icon }}">
  <input
    [id]="id"
    [disabled]="forceDisabled != null ? forceDisabled : disabled"
    [readOnly]="forceReadOnly != null ? forceReadOnly : readOnly"
    [class.has-error]="hasError"
    [checked]="forceChecked != null ? forceChecked : value === groupValue"
    [name]="name"
    type="radio"
    (change)="select()"
  />
  <label [for]="id">
    <strong *ngIf="label">{{ label }}</strong>
    <small *ngIf="description">{{ description }}</small>
  </label>
</div>

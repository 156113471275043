import {NgModule} from "@angular/core";
import {DOCTOR_SERVICE} from "./doctor-service.constants";
import {DoctorService} from "./doctor.service";

@NgModule({
  providers: [
    {
      provide: DOCTOR_SERVICE,
      useClass: DoctorService
    }
  ]
})
export class DoctorServiceModule {
}

import { createAction } from '@ngrx/store';

export class PortalLayoutActions {
  //#region Properties

  public static readonly toggleCollapse = createAction(
    '[LAYOUT] TOGGLE COLLAPSE'
  );
  public static readonly showLoading = createAction('[LAYOUT] SHOW LOADING');
  public static readonly hideLoading = createAction('[LAYOUT] HIDE LOADING');
  //#endregion
}

import { createAction, props } from '@ngrx/store';
import { Themes } from "@main-data-access-enums";
import { IActionProperty } from "../../interfaces";

export class LayoutActions {

  //#region Properties

  public static readonly changeTheme = createAction(
    '[LAYOUT] CHANGE THEME',
    props<IActionProperty<Themes>>()
  );

  public static readonly setBreadcrumb = createAction(
    '[LAYOUT] SET BREAD CRUMB',
    props<IActionProperty<string>>()
  );

  public static readonly toggleDrawer = createAction(
    '[LAYOUT] TOGGLE DRAWER SIZE'
  );

  //#endregion
}

import { MonoTypeOperatorFunction, Subject, takeUntil } from 'rxjs';

export function autoDestroy(): ClassDecorator {
  return (constructor): void => {
    const originOnDestroy = constructor.prototype.ngOnDestroy;

    constructor.prototype.destroy$ = new Subject();
    constructor.prototype.ngOnDestroy = (): void => {
      constructor.prototype.destroy$.next(0);
      constructor.prototype.destroy$.complete();

      if (originOnDestroy) {
        originOnDestroy.bind(constructor.prototype);
      }
    };
  };
}

export function followDestroy<T>(instance: any): MonoTypeOperatorFunction<T> {
  return (source$) => source$.pipe(takeUntil(instance.destroy$));
}

<button
  #datePickerDropdown="mOcloudDSDropdown"
  [cdkMenuTriggerFor]="containerTemplate"
  [cdkMenuPosition]="positions"
  mOcloudDSDropdown
  class="ds-date-picker"
  [class.disabled]="disabled"
  [class.read-only]="readOnly"
  [class.has-error]="hasError"
  [class.focused]="isOpen"
  [offsetY]="offsetY"
  [offsetX]="offsetX"
  [fixedWidth]="fixedWidth"
  (cdkMenuOpened)="onTouchedCallback(); isOpen = true"
  (cdkMenuClosed)="onTouchedCallback(); isOpen = false"
>
  <m-ocloud-ds-date-picker-box
    [text]="renderTemplate"
    [isPlaceholder]="isPlaceholder"
  ></m-ocloud-ds-date-picker-box>
</button>
<ng-template #containerTemplate>
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'range'">
      <m-ocloud-ds-date-picker-range
        [value]="rangeValue"
        (valueChange)="updateTempValue($any($event))"
        (applyClick)="writeValue(tempValue); datePickerDropdown.close(true)"
        (cancelClick)="updateTempValue(value); datePickerDropdown.close(true)"
        [text]="renderRangeTemplate"
        [isPlaceholder]="isPlaceholder"
        [min]="min"
        [max]="max"
      ></m-ocloud-ds-date-picker-range>
    </ng-container>
    <ng-container *ngSwitchCase="'single'">
      <m-ocloud-ds-date-picker-single
        [value]="singleValue"
        (valueChange)="updateTempValue($any($event))"
        (applyClick)="writeValue(tempValue); datePickerDropdown.close(true)"
        (cancelClick)="updateTempValue(value); datePickerDropdown.close(true)"
        [text]="renderSingleTemplate"
        [isPlaceholder]="isPlaceholder"
        [min]="min"
        [max]="max"
      ></m-ocloud-ds-date-picker-single>
    </ng-container>
  </ng-container>
</ng-template>

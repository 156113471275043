import { NgModule } from '@angular/core';
import { DSTabsComponent } from './tabs.component';
import { DSTabsItemDirective } from './directives';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';

@NgModule({
  declarations: [DSTabsComponent, DSTabsItemDirective],
  imports: [NgForOf, NgIf, NgTemplateOutlet, RouterLink, RouterLinkActive],
  exports: [DSTabsComponent, DSTabsItemDirective],
})
export class DSTabsModule {}

import { Directive, HostListener, Input } from '@angular/core';
import { DSButtonAppearances, DSButtonSizes } from './enums';

@Directive({
  selector:
    'button[mOcloudDSButtonIcon], a[mOcloudDSButtonIcon], input[type="button"][mOcloudDSButtonIcon], input[type="submit"][mOcloudDSButtonIcon]',
  host: {
    '[class]': 'className',
    '[disabled]': 'disabled',
  },
})
export class DSButtonIconDirective {
  @Input('mOcloudDSButtonIcon')
  public appearance: DSButtonAppearances | `${DSButtonAppearances}` =
    DSButtonAppearances.PRIMARY;

  @Input()
  public size: DSButtonSizes | `${DSButtonSizes}` = DSButtonSizes.MD;

  @Input()
  public disabled = false;

  public get className(): string {
    return `ds-button-icon ds-button--${this.appearance} ds-button--${this.size}`;
  }

  @HostListener('click', ['$event'])
  public onClick(event: Event): void {
    if (this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
}

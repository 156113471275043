import { Inject, Injectable } from '@angular/core';
import { ITreatmentService } from './treatment-service.interface';
import { mergeMap, Observable } from 'rxjs';
import {
  ITreatment,
  ITreatmentsSearchCondition,
} from '@main-data-access-interfaces';
import { HttpClient } from '@angular/common/http';
import { SearchResult } from '@main-data-access-models';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@main-data-access-resolvers';

@Injectable()
export class TreatmentService implements ITreatmentService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _apiEndpointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion Constructor

  //#region Methods

  public getTreatmentsAsync(): Observable<ITreatment[]> {
    throw new Error('Unimplemented');
  }

  public searchTreatmentsAsync(
    condition: ITreatmentsSearchCondition
  ): Observable<SearchResult<ITreatment>> {
    return this._apiEndpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/treatments/search`;
        return this._httpClient.post<SearchResult<ITreatment>>(
          apiUrl,
          condition
        );
      })
    );
  }

  public getTreatmentByIdAsync(id: string): Observable<ITreatment> {
    throw new Error('Unimplemented');
  }

  //#endregion Methods
}

export enum DSAlertHorizontalPositions {
  START = 'start',
  CENTER = 'center',
  END = 'end',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum DSAlertVerticalPositions {
  MIDDLE = 'middle',
  TOP = 'top',
  BOTTOM = 'bottom',
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IDSSelectItem } from '../../interfaces';
import {
  DSSelectFilterBys,
  DSSelectFilterModes,
  DSSelectItemKinds,
  DSSelectItemTypes,
} from '../../enums';
import { BehaviorSubject } from 'rxjs';
import { DSIconCategories } from '@design-system';

@Component({
  selector: 'm-ocloud-ds-select-container',
  templateUrl: './select-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '"contents"',
  },
})
export class DSSelectContainerComponent<TType extends any> {
  @Output()
  public readonly filterKeyChange = new EventEmitter<string>();

  @Output()
  public readonly itemClick = new EventEmitter<IDSSelectItem<TType>>();

  @Input()
  public set data(data: IDSSelectItem<TType>[] | null) {
    this.data$.next(data || []);
  }

  @Input()
  public maxHeight = 'auto';

  @Input()
  public customClasses = '';

  @Input()
  public selectedValue: TType | null = null;

  @Input()
  public filterKey = '';

  @Input()
  public filterPlaceHolder = '';

  @Input()
  public filterMode: DSSelectFilterModes | `${DSSelectFilterModes}` | null =
    null;

  @Input()
  public filterBy: DSSelectFilterBys | `${DSSelectFilterBys}` =
    DSSelectFilterBys.INSIDE;

  public readonly data$ = new BehaviorSubject<IDSSelectItem<TType>[]>([]);

  public get filterItem(): IDSSelectItem<TType> {
    return {
      text: this.filterKey,
      value: this.filterKey as TType,
      customClasses: 'text-branding-warning-500',
      kind: DSSelectItemKinds.DEFAULT,
      type: DSSelectItemTypes.DEFAULT,
      icon: 'tick-square',
      iconCategory: DSIconCategories.BOLD,
    } as IDSSelectItem<TType>;
  }
  public constructor(protected readonly _cdr: ChangeDetectorRef) {}

  public input(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.filterKey = value;
    this.filterKeyChange.emit(this.filterKey);
    this._cdr.markForCheck();
  }
}

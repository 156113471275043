import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'm-ocloud-ds-date-picker-box',
  templateUrl: './date-picker-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '"contents"',
  },
})
export class DSDatePickerBoxComponent {
  @Input()
  public text = '';

  @Input()
  public isPlaceholder = false;

  @Input()
  public customClasses = '';
}

import { Inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { IPatientService, PATIENT_SERVICE } from '@main-data-access-services';
import { IPatientDetailState } from './patient-detail.state';
import { pipe, switchMap, take, tap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutePaths } from '@main-data-access-enums';
import { Store } from '@ngrx/store';
import { DS_ALERT_SERVICE_TOKEN, IDSAlertService } from '@design-system';

@Injectable()
export class PatientDetailComponentStore extends ComponentStore<{}> {
  //#region Properties

  //#endregion

  //#region Constructor

  public constructor(
    @Inject(PATIENT_SERVICE) private readonly _patientService: IPatientService,
    @Inject(DS_ALERT_SERVICE_TOKEN)
    private readonly _alertService: IDSAlertService,
    protected readonly _store: Store<IPatientDetailState>,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _router: Router
  ) {
    super({
      patient: undefined,
    });
  }

  //#endregion

  //#region Methods

  public readonly remove = this.effect<never>(
    pipe(
      switchMap(() => this._activatedRoute.params),
      tap((data) => {
        this._alertService.success('Success', 'Remove patient successfully!!');
        this._router.navigate(['/', RoutePaths.PATIENT]);
      })
    )
  );

  //#endregion
}

import { Inject, Injectable } from '@angular/core';
import { mergeMap, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@main-data-access-resolvers';
import { IDiseasesService } from './diseases-service.interface';

@Injectable()
export class DiseasesService implements IDiseasesService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endpointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion Constructor

  //#region Methods

  public getDiseasesAsync(): Observable<{ id: number; name: string }[]> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        return this._httpClient.get<{ id: number; name: string }[]>(
          `${baseUrl}/diseases`
        );
      })
    );
  }

  //#endregion Methods
}

import { Inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  EMPTY,
  finalize,
  map,
  pipe,
  switchMap,
  tap,
} from 'rxjs';
import { IUsersSearchCondition } from '@main-data-access-interfaces';
import {
  ISmartNavigatorService,
  ISpinnerService,
  SMART_NAVIGATOR_SERVICE,
  SPINNER_SERVICE,
} from '@ui-tool/core';
import { IUsersComponentState } from './users.state';
import { IUserService, USER_SERVICE } from '@main-data-access-services';
import { DS_ALERT_SERVICE_TOKEN, IDSAlertService } from '@design-system';

@Injectable()
export class UsersComponentStore extends ComponentStore<IUsersComponentState> {
  //#region Properties
  protected readonly _refresh$ = new BehaviorSubject<Date>(new Date());

  //#endregion Properties

  //#region Constructor

  public constructor(
    @Inject(USER_SERVICE) protected readonly _userService: IUserService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    @Inject(SPINNER_SERVICE)
    protected readonly _spinnerService: ISpinnerService,
    @Inject(DS_ALERT_SERVICE_TOKEN)
    protected readonly _alertService: IDSAlertService
  ) {
    super({
      searchResult: { records: [], total: 0 },
      search: {
        email: '',
        status: 'all',
        role: 'all',
        doctorId: '',
        limit: 10,
        page: 1,
      },
      loading: true,
    });
  }

  //#endregion Constructor

  //#region Methods

  public readonly updatePage = this.updater<
    IUsersComponentState['search']['page']
  >((state, page) => ({
    ...state,
    search: {
      ...state.search,
      page,
    },
  }));

  public readonly updateLimit = this.updater<
    IUsersComponentState['search']['limit']
  >((state, limit) => ({
    ...state,
    search: {
      ...state.search,
      limit,
    },
  }));

  public readonly updateSearch = this.updater<IUsersComponentState['search']>(
    (state, search) => ({
      ...state,
      search: {
        ...state.search,
        ...search,
        page: 1,
      },
    })
  );

  public readonly loadUsers = this.effect<
    (data: IUsersComponentState['searchResult']) => void
  >(
    pipe(
      switchMap((cb) =>
        combineLatest([
          this.select((state) => state.search),
          this._refresh$,
        ]).pipe(
          map(([search]) => {
            Object.keys(search).forEach((key) => {
              const value = search[key as keyof IUsersSearchCondition];
              if (value == null || value == '') {
                delete search[key as keyof IUsersSearchCondition];
              }

              if ((key === 'status' || key === 'role') && value === 'all') {
                delete search[key as keyof IUsersSearchCondition];
              }
            });
            return { cb, search };
          })
        )
      ),
      debounceTime(250),
      switchMap(({ cb, search }) => {
        this.patchState({
          loading: true,
        });
        return this._userService.getAsync(search).pipe(
          tap((data) => {
            cb(data);
            this.patchState({
              searchResult: data,
            });
          }),
          catchError((exception) => {
            console.error(exception);
            this._alertService.error('Error', exception.message);
            return EMPTY;
          }),
          finalize(() => {
            this.patchState({
              loading: false,
            });
          })
        );
      })
    )
  );

  public refresh(): void {
    this._refresh$.next(new Date());
  }
  //#endregion Methods
}

export enum DSTooltipDirections {
  TOP_CENTER = 'top-center',
  BOTTOM_CENTER = 'top-center',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  LEFT_CENTER = 'left-center',
  RIGHT_CENTER = 'right-center',
}

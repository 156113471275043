import { Inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  BehaviorSubject,
  combineLatest,
  finalize,
  mergeMap,
  pipe,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { IRootState } from '../../../../root';
import { PatientDetailSelectors } from '../patient-detail.selectors';
import { IPatientSymptomsComponentState } from './patient-symptoms.state';
import { ISymptomsService, SYMPTOMS_SERVICE } from '@main-data-access-services';

@Injectable()
export class PatientSymptomsComponentStore extends ComponentStore<IPatientSymptomsComponentState> {
  //#region Properties

  protected readonly _patient$ = this._store.select(
    PatientDetailSelectors.patient
  );

  protected readonly _refresh$ = new BehaviorSubject<Date>(new Date());

  //#endregion

  //#region Constructor

  public constructor(
    @Inject(SYMPTOMS_SERVICE)
    protected readonly _symptomsService: ISymptomsService,
    protected readonly _store: Store<IRootState>
  ) {
    super({
      searchResult: {
        total: 0,
        records: [],
      },
      condition: {
        page: 1,
        limit: 10,
      },
      loading: false,
    });
  }

  //#endregion

  //#region Methods

  public readonly searchPatientSymptoms = this.effect<never>(
    pipe(
      switchMap(() => {
        return combineLatest([
          this._patient$.pipe(take(1)),
          this.select((x) => x.condition).pipe(take(1)),
          this._refresh$,
        ]);
      }),
      mergeMap(([patient, condition, search]) => {
        this.patchState({
          loading: true,
        });
        this.patchState({ condition });
        return this._symptomsService
          .getByPatientMrnAsync(condition, patient!.mrn)
          .pipe(
            tap((loadSymptomsResult) =>
              this.patchState({
                searchResult: loadSymptomsResult,
              })
            ),
            finalize(() => {
              this.patchState({
                loading: false,
              });
            })
          );
      })
    )
  );

  public refresh(): void {
    this._refresh$.next(new Date());
  }

  //#endregion Methods
}

import { NgModule } from '@angular/core';
import { DSDatePickerComponent } from './date-picker.component';
import { CdkMenuModule } from '@angular/cdk/menu';
import { DSDropdownModule } from '../../cdk/dropdown';
import { DSIconModule } from '../../common/icon';
import {
  DSDatePickerRangeComponent,
  DSDatePickerSingleComponent,
} from './containers';
import {
  DSDatePickerActionsComponent,
  DSDatePickerItemComponent,
  DSDatePickerMenuComponent,
  DSDatePickerNavigationComponent,
  DSDatePickerValueComponent,
  DSDatePickerDateComponent,
  DSDatePickerBoxComponent,
} from './components';
import {
  DatePipe,
  NgForOf,
  NgIf,
  NgSwitch,
  NgSwitchCase,
} from '@angular/common';
import { DSFieldModule } from '../field';
import { DSButtonModule } from '../../common/button';
import { FormsModule } from '@angular/forms';
import { DSInputModule } from '../input';
import { DSDatePickerActivePipe } from './pipes';

@NgModule({
  declarations: [
    DSDatePickerComponent,
    DSDatePickerSingleComponent,
    DSDatePickerRangeComponent,
    DSDatePickerActionsComponent,
    DSDatePickerItemComponent,
    DSDatePickerMenuComponent,
    DSDatePickerNavigationComponent,
    DSDatePickerValueComponent,
    DSDatePickerDateComponent,
    DSDatePickerBoxComponent,
    DSDatePickerActivePipe,
  ],
  imports: [
    DSDropdownModule,
    CdkMenuModule,
    NgSwitch,
    NgSwitchCase,
    DSIconModule,
    NgForOf,
    DSButtonModule,
    DSFieldModule,
    FormsModule,
    DSInputModule,
    DatePipe,
    NgIf,
  ],
  exports: [
    DSDatePickerComponent,
    DSDatePickerSingleComponent,
    DSDatePickerRangeComponent,
    DSDatePickerActionsComponent,
    DSDatePickerItemComponent,
    DSDatePickerMenuComponent,
    DSDatePickerNavigationComponent,
    DSDatePickerValueComponent,
    DSDatePickerDateComponent,
    DSDatePickerBoxComponent,
    DSDatePickerActivePipe,
  ],
})
export class DSDatePickerModule {}

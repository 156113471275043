import { NgModule } from '@angular/core';
import { DS_APPLICATION_SERVICE_TOKEN } from './application-service.token';
import { DSApplicationService } from './application.service';

@NgModule({
  providers: [
    {
      provide: DS_APPLICATION_SERVICE_TOKEN,
      useClass: DSApplicationService,
    },
  ],
})
export class DSApplicationServiceModule {}

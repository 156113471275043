import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { IPortalLayoutStoreState } from './portal-layout.state';

export const portalLayoutAdapter: EntityAdapter<never> =
  createEntityAdapter<never>();

export const portalLayoutStoreInitialState: IPortalLayoutStoreState =
  portalLayoutAdapter.getInitialState({
    collapse: false,
    loading: false,
  });

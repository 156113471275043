import { NgModule } from '@angular/core';
import { DSTagsComponent } from './tags.component';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { DSIconModule } from '../icon';

@NgModule({
  declarations: [DSTagsComponent],
  imports: [NgIf, NgSwitch, NgSwitchCase, DSIconModule],
  exports: [DSTagsComponent],
})
export class DSTagsModule {}

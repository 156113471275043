import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { DSButtonGroupItemDirective } from './button-group-item.directive';
import { BehaviorSubject } from 'rxjs';
import { autoDestroy, followDestroy } from '../../shared';

@Component({
  selector: 'm-ocloud-ds-button-group',
  templateUrl: './button-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '"contents"',
  },
})
@autoDestroy()
export class DSButtonGroupComponent {
  public readonly activeKey$ = new BehaviorSubject<string | null>(null);

  @Input()
  public set activeKey(key: string | null) {
    this.activeKey$.next(key);
  }

  @Output()
  public readonly activeKeyChange = this.activeKey$
    .asObservable()
    .pipe(followDestroy(this));

  @ContentChildren(DSButtonGroupItemDirective)
  public readonly itemDirectives: QueryList<DSButtonGroupItemDirective> | null =
    null;

  @Input()
  public ignoreItemBorder = false;

  public get renderItems(): DSButtonGroupItemDirective[] {
    return Array.from(this.itemDirectives || []);
  }

  public itemClick(item: DSButtonGroupItemDirective): void {
    if (!item.disableSelectAction) {
      this.activeKey$.next(item.key);
    }
    item.clickEvent.emit();
  }
}

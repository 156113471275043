import {Inject, Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {Router} from '@angular/router';
import {ISmartNavigatorService, SMART_NAVIGATOR_SERVICE, WINDOW} from '@ui-tool/core';
import {AUTHENTICATION_SERVICE, IAuthenticationService,} from '@main-data-access-services';
import {IUnavailableUserState} from './unavailable-user.state';
import {
  AuthenticationProviders,
  AuthenticationStatuses,
  AuthenticationUrlParams,
  AzureAdUrlParams, ExceptionCodes,
} from '@main-data-access-enums';
import {catchError, delay, map, mergeMap, pipe, switchMap, throwError} from 'rxjs';
import {DashboardPageNavigationRequest, LoginPageNavigationRequest} from '@main-data-access-models';

@Injectable()
export class UnavailableUserStore extends ComponentStore<IUnavailableUserState> {
  //#region Constructor

  public constructor(
    @Inject(WINDOW)
    protected readonly _window: Window,
    @Inject(AUTHENTICATION_SERVICE)
    protected readonly _authenticationService: IAuthenticationService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    protected readonly _router: Router
  ) {
    super({
      authenticationStatus: AuthenticationStatuses.AUTHENTICATING,
    });
  }

  //#endregion

  //#region Methods

  public goToLoginPage = this.effect<never>(
    pipe(
      switchMap(() => this._navigationService.navigateToScreenAsync(new LoginPageNavigationRequest()))
    )
  );

  //#endregion
}

import { NgModule } from '@angular/core';
import { DiseasesService } from './diseases.service';
import { DISEASES_SERVICE } from './diseases-service.constants';

@NgModule({
  providers: [
    {
      provide: DISEASES_SERVICE,
      useClass: DiseasesService,
    },
  ],
})
export class DiseasesServiceModule {}

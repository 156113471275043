import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { IPatientService, PATIENT_SERVICE } from '@main-data-access-services';
import {
  IMeetRequirementService,
  REQUIREMENT_SENTINEL_SERVICE,
} from '@ui-tool/core';
import { Store } from '@ngrx/store';
import { IPatientDetailState } from '@main-data-access-stores';
import { catchError, mergeMap, Observable, of, throwError } from 'rxjs';
import { ExceptionCodes, RequirementNames } from '@main-data-access-enums';
import { DS_ALERT_SERVICE_TOKEN, IDSAlertService } from '@design-system';

@Injectable()
export class PatientDiagnosesGuard implements CanActivate {
  //#region Constructor

  public constructor(
    @Inject(DS_ALERT_SERVICE_TOKEN)
    protected readonly _alertService: IDSAlertService,
    @Inject(PATIENT_SERVICE)
    protected readonly _patientService: IPatientService,
    @Inject(REQUIREMENT_SENTINEL_SERVICE)
    protected readonly _requirementSentinelService: IMeetRequirementService,
    protected readonly _store: Store<IPatientDetailState>
  ) {}

  //#endregion

  //#region Methods

  public canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._requirementSentinelService
      .shouldRequirementMetAsync(RequirementNames.ABLE_TO_VIEW_DIAGNOSES)
      .pipe(
        mergeMap((ableToView) => {
          if (!ableToView) {
            return throwError(
              ExceptionCodes.DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_FEATURE
            );
          }

          return of(true);
        }),
        catchError((exception) => {
          let message = 'Something went wrong while accessing this feature';

          if (exception === ExceptionCodes.PATIENT_NOT_FOUND) {
            message = 'Patient is not found';
          } else if (
            exception ===
            ExceptionCodes.DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_FEATURE
          ) {
            message = 'You dont have enough permission to access this feature';
          }

          this._alertService.error('Error', message);

          return of(false);
        })
      );
  }

  //#endregion
}

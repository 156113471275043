import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DSDatePickerItemTypes } from '../../enums';
import { compareDesc } from 'date-fns';
import { toBasicDate } from '../../constants';

@Component({
  selector: 'm-ocloud-ds-date-picker-item',
  templateUrl: './date-picker-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '"contents"',
  },
})
export class DSDatePickerItemComponent {
  @Output()
  public readonly itemClick = new EventEmitter<Date | null>();

  @Input()
  public text = '';

  @Input()
  public value: Date | null = null;

  @Input()
  public disabled = false;

  @Input()
  public type: DSDatePickerItemTypes | `${DSDatePickerItemTypes}` =
    DSDatePickerItemTypes.DAY;

  @Input()
  public customClasses = '';

  public get isToday(): boolean {
    if (!this.value) {
      return false;
    }

    return compareDesc(toBasicDate(this.value), toBasicDate(new Date())) === 0;
  }
}

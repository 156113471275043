import { LAYOUT_THEME, layoutStoreInitialState } from "./layout.adapter";
import { LayoutActions } from "./layout.actions";
import { createReducer, on } from "@ngrx/store";

//#region Properties

export const layoutStoreKey = 'layout';

export const layoutReducer = createReducer(
  layoutStoreInitialState,
  on(LayoutActions.changeTheme, (state, action) => {
    localStorage.setItem(LAYOUT_THEME, action.data);
    return {
      ...state,
      theme: action.data
    };
  }),
  on(LayoutActions.toggleDrawer, (state, action) => ({
    ...state,
    showDrawer: !state.showDrawer
  })),
  on(LayoutActions.setBreadcrumb, (state, action) => ({
    ...state,
    breadcrumb: action.data
  }))
);

//#endregion

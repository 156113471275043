<section class="ds-date-picker-value ds-date-picker-value--{{ type }}">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'single'">
      <m-ocloud-ds-field>
        <input
          mOcloudDSInput
          [readOnly]="true"
          [placeholder]="isPlaceholder ? text : ''"
          [value]="isPlaceholder ? '' : text"
        />
      </m-ocloud-ds-field>
      <button [mOcloudDSButton]="'secondary-gray'" (click)="todayClick.emit()">
        Today
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'range'">
      <m-ocloud-ds-field>
        <input
          mOcloudDSInput
          [readOnly]="true"
          [placeholder]="isPlaceholder ? rangeText[0] : ''"
          [value]="isPlaceholder ? '' : rangeText[0]"
        />
      </m-ocloud-ds-field>
      <hr />
      <m-ocloud-ds-field>
        <input
          mOcloudDSInput
          [readOnly]="true"
          [placeholder]="isPlaceholder ? rangeText[1] : ''"
          [value]="isPlaceholder ? '' : rangeText[1]"
        />
      </m-ocloud-ds-field>
    </ng-container>
  </ng-container>
</section>

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  Optional,
} from '@angular/core';
import { DSTooltipDirections, DSTooltipThemes } from './enums';
import { DS_TOOLTIP_DATA_TOKEN } from './tokens';
import { DSTooltip } from './interfaces';

@Component({
  selector: 'm-ocloud-ds-tooltip',
  templateUrl: './tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '"contents"',
  },
})
export class DSTooltipComponent {
  @Input()
  public label = '';

  @Input()
  public description: string | null = null;

  @Input()
  public theme: DSTooltipThemes | `${DSTooltipThemes}` = DSTooltipThemes.DARK;

  @Input()
  public direction: DSTooltipDirections | `${DSTooltipDirections}` =
    DSTooltipDirections.BOTTOM_CENTER;

  public constructor(
    @Optional()
    @Inject(DS_TOOLTIP_DATA_TOKEN)
    protected readonly _data: DSTooltip
  ) {
    if (_data) {
      const { label, description, theme, direction } = _data;
      this.label = label;
      this.description = description;
      this.theme = theme;
      this.direction = direction;
    }
  }
}

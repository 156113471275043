import { NgModule } from '@angular/core';
import { DSToggleComponent } from './toggle.component';
import { NgIf } from '@angular/common';

@NgModule({
  declarations: [DSToggleComponent],
  exports: [DSToggleComponent],
  imports: [NgIf],
})
export class DSToggleModule {}

import { NgModule } from "@angular/core";
import { AuthenticationService } from "./authentication.service";
import {AUTHENTICATION_SERVICE} from './authentication-service.constants';

@NgModule({
  providers: [
    {
      provide: AUTHENTICATION_SERVICE,
      useClass: AuthenticationService
    }
  ]
})
export class AuthenticationServiceModule { }

import {
  layoutReducer,
  layoutStoreKey,
  portalLayoutReducer,
  portalLayoutStoreKey,
  profileReducer,
  profileStoreKey,
} from './features';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '@main-resources-environments';
import { IRootState } from './root.state';
import { storeFreeze } from 'ngrx-store-freeze';
import {
  patientDetailReducer,
  patientDetailStoreKey,
} from '../component/patient/detail/patient-detail.reducer';
import {
  appointmentDetailReducer,
  appointmentDetailStoreKey,
} from '../component';

export const reducers: ActionReducerMap<IRootState> = {
  [patientDetailStoreKey]: patientDetailReducer,
  [layoutStoreKey]: layoutReducer,
  [portalLayoutStoreKey]: portalLayoutReducer,
  [appointmentDetailStoreKey]: appointmentDetailReducer,
  [profileStoreKey]: profileReducer,
};

export const metaReducers: MetaReducer<IRootState>[] = !environment.production
  ? [storeFreeze]
  : [];

export const effects = [];

import {NgModule} from '@angular/core';
import {FEATURE_SENTINEL_SERVICE} from '@ui-tool/core';
import {FeatureSentinelService} from "./feature-sentinel.service";

@NgModule({
  providers: [
    {
      provide: FEATURE_SENTINEL_SERVICE,
      useClass: FeatureSentinelService,
    },
  ],
})
export class FeatureSentinelServiceModule {
}

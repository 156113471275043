<article
  class="ds-modal ds-modal--{{ status }} ds-modal--{{
    footerDirections
  }} ds-modal--{{ size }}"
>
  <button
    *ngIf="!hiddenCloseButton"
    class="ds-modal__close"
    [mOcloudDSButtonIcon]="'tertiary-gray'"
    [size]="'lg'"
    (click)="closeButtonClick.emit()"
  >
    <i [mOcloudDSIcon]="'x'" [category]="'custom'"></i>
  </button>
  <header>
    <ng-container *ngIf="status === 'form'; else iconTemplate">
      <h4>{{ title }}</h4>
    </ng-container>
    <ng-template #iconTemplate>
      <span [inlineSVG]="statusIconLink"></span>
    </ng-template>
  </header>
  <main>
    <ng-container
      *ngIf="bodyTemplate?.templateRef as ref; else bodyDefaultTemplate"
    >
      <ng-container *ngTemplateOutlet="ref"></ng-container>
    </ng-container>
    <ng-template #bodyDefaultTemplate>
      <h4 *ngIf="title">{{ title }}</h4>
      <p *ngIf="description">{{ description }}</p>
    </ng-template>
  </main>
  <footer>
    <button
      *ngIf="!hiddenCancelButton"
      [mOcloudDSButton]="cancelButtonAppearance"
      [disabled]="cancelButtonDisabled"
      (click)="!cancelButtonDisabled && cancelButtonClick.emit()"
    >
      {{ cancelButtonLabel }}
    </button>
    <button
      *ngIf="!hiddenConfirmButton"
      [mOcloudDSButton]="confirmButtonAppearance"
      [disabled]="confirmButtonDisabled"
      (click)="!confirmButtonDisabled && confirmButtonClick.emit()"
    >
      {{ confirmButtonLabel }}
    </button>
  </footer>
</article>

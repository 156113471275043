<section class="ds-date-picker-date">
  <ng-container *ngFor="let weekDay of weekDays">
    <m-ocloud-ds-date-picker-item
      [type]="'week-day'"
      [text]="weekDay"
    ></m-ocloud-ds-date-picker-item>
  </ng-container>
  <ng-container *ngFor="let day of days">
    <m-ocloud-ds-date-picker-item
      (itemClick)="selectDate(day)"
      [type]="'day'"
      [customClasses]="
        day.customClasses +
        ' ' +
        (day.value | mOcloudDSDatePickerActive: type:value)
      "
      [text]="day.text"
      [value]="day.value"
      [disabled]="day.disabled"
    ></m-ocloud-ds-date-picker-item>
  </ng-container>
</section>

import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { effects, metaReducers, reducers } from './root.store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from "@main-resources-environments";

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, {metaReducers}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !environment.production,
    }),
    EffectsModule.forRoot(effects),
  ],
  exports: [StoreModule, EffectsModule, StoreDevtoolsModule],
})
export class RootStoreModule {
}

import { Inject, Injectable } from '@angular/core';
import { RequirementNames, UserFeatures } from '@main-data-access-enums';
import {
  FEATURE_SENTINEL_SERVICE,
  IFeatureSentinelService,
  IRequirementHandler,
} from '@ui-tool/core';
import { Observable } from 'rxjs';

@Injectable()
export class AbleToViewUsersRequirementHandler implements IRequirementHandler {
  //#region Properties

  public readonly name = RequirementNames.ABLE_TO_VIEW_USERS;

  //#endregion

  //#region Constructor

  public constructor(
    @Inject(FEATURE_SENTINEL_SERVICE)
    protected _featureSentinelService: IFeatureSentinelService
  ) {}

  //#endregion

  //#region Methods

  public shouldRequirementMetAsync(): Observable<boolean> {
    return this._featureSentinelService.ableToAccessFeaturesAsync([
      UserFeatures.SEARCH,
    ]);
  }

  //#endregion
}

import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslocoLoaderData } from '@ngneat/transloco/lib/transloco.loader';
import { Observable } from 'rxjs';

@Injectable()
export class TranslocoHttpLoader implements TranslocoLoader {
  //#region Constructor

  public constructor(protected readonly _httpClient: HttpClient) {}

  //#nedregion

  //#region  Methods

  public getTranslation(
    lang: string,
    data?: TranslocoLoaderData
  ): Observable<Translation> | Promise<Translation> {
    return this._httpClient.get<Translation>(`/m-ocloud-assets/i18n/${lang}.json`);
  }

  //#endregion
}

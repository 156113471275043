import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DSDatePickerMenuTypes } from '../../enums';

@Component({
  selector: 'm-ocloud-ds-date-picker-menu',
  templateUrl: './date-picker-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '"contents"',
  },
})
export class DSDatePickerMenuComponent {
  @Output()
  public readonly itemClick = new EventEmitter<DSDatePickerMenuTypes>();

  @Input()
  public customClasses = '';

  @Input()
  public items: DSDatePickerMenuTypes[] = [
    DSDatePickerMenuTypes.TODAY,
    DSDatePickerMenuTypes.YESTERDAY,
    DSDatePickerMenuTypes.THIS_WEEK,
    DSDatePickerMenuTypes.LAST_WEEK,
    DSDatePickerMenuTypes.THIS_MONTH,
    DSDatePickerMenuTypes.LAST_MONTH,
    DSDatePickerMenuTypes.THIS_YEAR,
    DSDatePickerMenuTypes.LAST_YEAR,
    DSDatePickerMenuTypes.ALL_TIME,
  ];
}

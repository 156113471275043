import {Inject, Injectable} from '@angular/core';
import {AppointmentFeatures, RequirementNames} from '@main-data-access-enums';
import {FEATURE_SENTINEL_SERVICE, IFeatureSentinelService, IRequirementHandler} from '@ui-tool/core';
import {Observable} from 'rxjs';

@Injectable()
export class AbleToViewAppointmentHistoriesRequirementHandler implements IRequirementHandler {

  //#region Properties

  public readonly name = RequirementNames.ABLE_TO_VIEW_APPOINTMENT_HISTORIES;

  //#endregion

  //#region Constructor

  public constructor(@Inject(FEATURE_SENTINEL_SERVICE)
                     protected readonly _featureSentinelService: IFeatureSentinelService) {
  }

  //#endregion

  //#region Methods

  public shouldRequirementMetAsync(): Observable<boolean> {
    return this._featureSentinelService.ableToAccessFeaturesAsync([AppointmentFeatures.VIEW_HISTORIES]);

  }

  //#endregion

}

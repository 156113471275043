<ng-container *ngIf="data">
  <article
    class="ds-select-item {{ data.customClasses }}"
    [class.disabled]="data.disabled"
    [class.active]="!!isSelected"
  >
    <main>
      <ng-container [ngSwitch]="data.type">
        <ng-container *ngSwitchCase="'checkbox'">
          <m-ocloud-ds-checkbox
            [label]="data.text"
            [forceChecked]="!!isSelected"
            [disabled]="!!data.disabled"
          ></m-ocloud-ds-checkbox>
        </ng-container>
        <ng-container *ngSwitchCase="'radio'">
          <m-ocloud-ds-radio
            [label]="data.text"
            [forceChecked]="!!isSelected"
            [forceDisabled]="!!data.disabled"
          ></m-ocloud-ds-radio>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <label>{{ data.text }}</label>
        </ng-container>
      </ng-container>
    </main>
    <i
      *ngIf="data.icon && data.iconCategory"
      [mOcloudDSIcon]="data.icon"
      [category]="data.iconCategory"
      [class]="data.iconCustomClasses"
    ></i>
  </article>
</ng-container>

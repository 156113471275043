import { NavigateToScreenRequest } from '@ui-tool/core';
import { ScreenCodes } from '@main-data-access-enums';

export class PatientsPageNavigationRequest extends NavigateToScreenRequest<void> {
  //#region Constructor

  public constructor() {
    super(ScreenCodes.PATIENTS);
  }

  //#endregion
}

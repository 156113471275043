import { Directive, Input } from '@angular/core';
import { DSIconCategories } from './enums';

@Directive({
  selector: 'i[mOcloudDSIcon]',
  host: {
    '[class]': 'className',
    '[style.--ds-icon-font-size]': 'size',
  },
})
export class DSIconDirective {
  @Input('mOcloudDSIcon')
  public name = '';

  @Input()
  public category: DSIconCategories | `${DSIconCategories}` =
    DSIconCategories.BOLD;

  @Input()
  public size = '';

  public get className(): string {
    return `ds-icon ${this.category}-${this.name}`;
  }
}

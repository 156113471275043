import { Inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, finalize, mergeMap, Observable, of } from 'rxjs';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@main-data-access-resolvers';
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from '@ui-tool/core';
import {
  AUTHENTICATION_SERVICE,
  IAuthenticationService,
} from '@main-data-access-services';
import { Store } from '@ngrx/store';
import { IRootState, PortalLayoutActions } from '@main-data-access-stores';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _apiEndpointResolver: IApiEndpointResolver,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    @Inject(AUTHENTICATION_SERVICE)
    protected readonly _authenticationService: IAuthenticationService,
    protected readonly _store: Store<IRootState>
  ) {}

  //#endregion

  //#region Methods

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this._store.dispatch(PortalLayoutActions.showLoading());
    return this._authenticationService.getAccessTokenAsync().pipe(
      catchError((exception) => of('')),
      mergeMap((accessToken) => {
        let httpHeaders = request.headers;

        if (accessToken) {
          httpHeaders = httpHeaders.set(
            'Authorization',
            `Bearer ${accessToken}`
          );
        }

        const clonedRequest = request.clone({
          headers: httpHeaders,
        });
        return next.handle(clonedRequest);
      }),
      finalize(() => {
        this._store.dispatch(PortalLayoutActions.hideLoading());
      })
    );
  }

  //#endregion
}

import {NgModule} from "@angular/core";
import {USER_SERVICE} from "./user-service.constants";
import {UserService} from "./user.service";

@NgModule({
  providers: [
    {
      provide: USER_SERVICE,
      useClass: UserService
    }
  ]
})
export class UserServiceModule {
}

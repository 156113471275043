import { NgModule } from '@angular/core';
import { SurgeryService } from './surgery.service';
import { SURGERY_SERVICE } from './surgery-service.constants';

@NgModule({
  providers: [
    {
      provide: SURGERY_SERVICE,
      useClass: SurgeryService,
    },
  ],
})
export class SurgeryServiceModule {}

import { Inject, Injectable } from '@angular/core';
import { mergeMap, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@main-data-access-resolvers';
import { ISurgeryService } from './surgery-service.interface';

@Injectable()
export class SurgeryService implements ISurgeryService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endpointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion Constructor

  //#region Methods

  public getAsync(
    diseaseIds?: string[]
  ): Observable<{ pilCode: string; name: string }[]> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        let httpParams: HttpParams = new HttpParams();
        if (diseaseIds && diseaseIds.length) {
          diseaseIds.forEach((item) => {
            httpParams = httpParams.append('diseaseIds', item);
          });
        }
        return this._httpClient.get<{ pilCode: string; name: string }[]>(
          `${baseUrl}/surgeries`,
          { params: httpParams }
        );
      })
    );
  }

  //#endregion Methods
}

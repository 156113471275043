import { Inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  ISmartNavigatorService,
  SMART_NAVIGATOR_SERVICE,
  WINDOW,
} from '@ui-tool/core';
import {
  AUTHENTICATION_SERVICE,
  IAuthenticationService,
} from '@main-data-access-services';
import { IOAuthState } from './oauth.state';
import {
  AuthenticationProviders,
  AuthenticationStatuses,
  AuthenticationUrlParams,
  AzureAdUrlParams,
  ExceptionCodes,
  UserRoles,
} from '@main-data-access-enums';
import {
  catchError,
  delay,
  EMPTY,
  map,
  mergeMap,
  of,
  pipe,
  switchMap,
} from 'rxjs';
import {
  DashboardPageNavigationRequest,
  LoginPageNavigationRequest,
  PatientsPageNavigationRequest,
  UnavailableUserPageNavigationRequest,
} from '@main-data-access-models';
import { Store } from '@ngrx/store';
import { IProfileState, ProfileActions } from '../../root';

@Injectable()
export class OauthStore extends ComponentStore<IOAuthState> {
  //#region Constructor

  public constructor(
    @Inject(WINDOW)
    protected readonly _window: Window,
    @Inject(AUTHENTICATION_SERVICE)
    protected readonly _authenticationService: IAuthenticationService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    protected readonly _store: Store<IProfileState>
  ) {
    super({
      authenticationStatus: AuthenticationStatuses.AUTHENTICATING,
      userProfile: null,
    });
  }

  //#endregion

  //#region Methods

  public readonly authenticate = this.effect<never>(
    pipe(
      map((queryParams) => {
        const addressUrl = new URL(
          this._window.location.href.replace(`#`, '&')
        );
        const searchParams = new URLSearchParams(addressUrl.search);
        const authenticationProvider = searchParams.get(
          AuthenticationUrlParams.PROVIDER
        );

        switch (authenticationProvider) {
          case AuthenticationProviders.AZURE_ACTIVE_DIRECTORY:
            // Get access token.
            const idToken = searchParams.get(AzureAdUrlParams.ID_TOKEN);
            if (!idToken) {
              throw new Error(ExceptionCodes.ID_TOKEN_NOT_FOUND);
            }
            return searchParams.get(AzureAdUrlParams.ID_TOKEN) as string;

          default:
            throw new Error(ExceptionCodes.AUTHENTICATION_PROVIDER_NOT_SUPPORT);
        }
      }),
      catchError((exception) => {
        this.patchState({
          authenticationStatus:
            AuthenticationStatuses.AUTHENTICATION_UNSUCCESSFUL,
          userProfile: null,
        });
        return EMPTY;
      }),
      mergeMap((accessToken) => {
        return this._authenticationService.saveAccessTokenAsync(accessToken);
      }),
      mergeMap(() => {
        return this._authenticationService.getProfileAsync().pipe(
          mergeMap((userProfile) => {
            this._store.dispatch(
              ProfileActions.saveProfile({ profile: userProfile })
            );
            this.patchState({
              authenticationStatus:
                AuthenticationStatuses.AUTHENTICATION_SUCCESSFUL,
              userProfile: userProfile,
            });
            return of(void 0).pipe(
              delay(1500),
              mergeMap(() => {
                switch (userProfile.role?.toUpperCase()) {
                  case UserRoles.ADMIN.toUpperCase():
                  case UserRoles.DATA_ADMIN.toUpperCase():
                    return this._navigationService.navigateToScreenAsync(
                      new DashboardPageNavigationRequest()
                    );

                  default:
                    return this._navigationService.navigateToScreenAsync(
                      new PatientsPageNavigationRequest()
                    );
                }
              })
            );
          }),
          catchError((exception) => {
            return this._authenticationService.deleteAccessTokenAsync().pipe(
              mergeMap(() => {
                const navigationRequest =
                  new UnavailableUserPageNavigationRequest();
                return this._navigationService.navigateToScreenAsync(
                  navigationRequest
                );
              })
            );
          })
        );
      })
    )
  );

  public goToLoginPage = this.effect<never>(
    pipe(
      switchMap(() =>
        this._navigationService.navigateToScreenAsync(
          new LoginPageNavigationRequest()
        )
      )
    )
  );

  //#endregion
}

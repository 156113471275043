import {createFeatureSelector, createSelector} from '@ngrx/store';
import {patientDetailStoreKey} from './patient-detail.reducer';
import {IPatientDetailState} from './patient-detail.state';

const selectPatientDetail =
  createFeatureSelector<IPatientDetailState>(patientDetailStoreKey);


export class PatientDetailSelectors {

  //#region Properties

  public static readonly patient = createSelector(selectPatientDetail, x => x.patient);

  //#endregion

}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  Optional,
} from '@angular/core';
import { v4 } from 'uuid';
import { IDSRadioGroupComponent } from './interfaces';
import { RADIO_GROUP_COMPONENT_TOKEN } from './tokens';
import { DSRadioIcons, DSRadioSizes } from './enums';

@Component({
  selector: 'm-ocloud-ds-radio',
  templateUrl: './radio.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '"contents"',
  },
})
export class DSRadioComponent<TType extends any> {
  @Input()
  public id = v4();

  @Input()
  public name = v4();

  @Input()
  public label = '';

  @Input()
  public description = '';

  @Input()
  public value: TType | null = null;

  @Input()
  public size: DSRadioSizes | `${DSRadioSizes}` = DSRadioSizes.SM;

  @Input()
  public icon: DSRadioIcons | `${DSRadioIcons}` = DSRadioIcons.CIRCLE;

  @Input()
  public groupValue: TType | null = null;

  @Input()
  public hasError = false;

  @Input()
  public forceChecked: boolean | null = null;

  @Input()
  public forceDisabled: boolean | null = null;

  @Input()
  public forceReadOnly: boolean | null = null;

  public disabled = false;
  public readOnly = false;

  public constructor(
    protected readonly _cdr: ChangeDetectorRef,
    @Optional()
    @Inject(RADIO_GROUP_COMPONENT_TOKEN)
    protected readonly _groupComponent: IDSRadioGroupComponent<TType>
  ) {
    this._groupComponent?.listenValueChange((value) => {
      this.groupValue = value;
      this._cdr.markForCheck();
    });
  }

  public select(): void {
    this._groupComponent?.writeValue(this.value);
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { layoutStoreKey } from "./layout.reducer";
import { ILayoutStoreState } from "./layout.state";

const layoutStoreSelector =
  createFeatureSelector<ILayoutStoreState>(layoutStoreKey);

export class LayoutSelectors {

  //#region Properties

  public static readonly theme = createSelector(layoutStoreSelector, (state) => state.theme);

  public static readonly shouldDrawerShown = createSelector(layoutStoreSelector, (state) => state.showDrawer);

  public static readonly breadcrumb = createSelector(layoutStoreSelector, (state) => state.breadcrumb);

  //#endregion

}

import {
  Directive,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { DSButtonGroupKinds } from './enums';
import { DSIconCategories } from '../icon';

@Directive({
  selector: 'ng-template[mOcloudDSButtonGroupItem]',
})
export class DSButtonGroupItemDirective {
  @Output()
  public readonly clickEvent = new EventEmitter();

  @Input()
  public kind: DSButtonGroupKinds | `${DSButtonGroupKinds}` =
    DSButtonGroupKinds.DEFAULT;

  @Input()
  public disabled = false;

  @Input()
  public key = '';

  @Input()
  public instance = '';

  @Input()
  public instanceCategory: DSIconCategories | `${DSIconCategories}` =
    DSIconCategories.BOLD;

  @Input()
  public iconLeft = '';

  @Input()
  public iconLeftCategory: DSIconCategories | `${DSIconCategories}` =
    DSIconCategories.BOLD;

  @Input()
  public iconRight = '';

  @Input()
  public iconRightCategory: DSIconCategories | `${DSIconCategories}` =
    DSIconCategories.BOLD;

  @Input()
  public disableSelectAction = false;

  @Input()
  public disableState = false;

  @Input()
  public customClasses = '';

  public constructor(public readonly templateRef: TemplateRef<HTMLElement>) {}
}

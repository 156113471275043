import { Inject, Injectable } from '@angular/core';
import { mergeMap, Observable } from 'rxjs';
import { ISymptom } from '@main-data-access-interfaces';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@main-data-access-resolvers';
import { SearchResult } from '@main-data-access-models';
import { ISymptomsService } from './symptoms-service.interface';

@Injectable()
export class SymptomsService implements ISymptomsService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endpointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion Constructor

  //#region Methods

  public getByPatientMrnAsync(
    condition: { page: number; limit: number },
    mrn: string
  ): Observable<SearchResult<ISymptom>> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        let httpParams: HttpParams = new HttpParams().append(
          'page',
          condition.page
        );
        httpParams = httpParams.append('limit', condition.limit);

        return this._httpClient.get<SearchResult<ISymptom>>(
          `${baseUrl}/symptoms/by-patient/${mrn}`,
          {
            params: httpParams,
          }
        );
      })
    );
  }

  //#endregion Methods
}

import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { catchError, mergeMap, Observable, of, throwError } from 'rxjs';
import { ExceptionCodes, UserRoles } from '@main-data-access-enums';
import { Store } from '@ngrx/store';
import {
  IPatientDetailState,
  ProfileSelectors,
} from '@main-data-access-stores';
import { DS_ALERT_SERVICE_TOKEN, IDSAlertService } from '@design-system';

@Injectable()
export class PatientCliniciansGuard implements CanActivate {
  //#region Constructor

  public constructor(
    @Inject(DS_ALERT_SERVICE_TOKEN)
    protected readonly _alertService: IDSAlertService,
    protected readonly _store: Store<IPatientDetailState>
  ) {}

  //#endregion

  //#region Methods

  public canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._store.select(ProfileSelectors.profile).pipe(
      mergeMap((profile) => {
        if (profile?.role === UserRoles.DOCTOR) {
          return throwError(
            ExceptionCodes.DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_FEATURE
          );
        }

        return of(true);
      }),
      catchError((exception) => {
        let message = 'Something went wrong while accessing this feature';

        if (exception === ExceptionCodes.PATIENT_NOT_FOUND) {
          message = 'Patient is not found';
        } else if (
          exception ===
          ExceptionCodes.DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_FEATURE
        ) {
          message = 'You dont have enough permission to access this feature';
        }

        this._alertService.error('Error', message);

        return of(false);
      })
    );
  }

  //#endregion
}

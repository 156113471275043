import { NgModule } from '@angular/core';
import { DSFieldComponent } from './field.component';
import {
  DSFieldControlPrefixDirective,
  DSFieldControlSuffixDirective,
} from './directives';
import { NgIf, NgTemplateOutlet } from '@angular/common';

@NgModule({
  declarations: [
    DSFieldComponent,
    DSFieldControlSuffixDirective,
    DSFieldControlPrefixDirective,
  ],
  exports: [
    DSFieldComponent,
    DSFieldControlSuffixDirective,
    DSFieldControlPrefixDirective,
  ],
  imports: [NgIf, NgTemplateOutlet],
})
export class DSFieldModule {}

import { NgModule } from '@angular/core';
import { DSTooltipDirective } from './tooltip.directive';
import { DSTooltipComponent } from './tooltip.component';
import { NgIf } from '@angular/common';

@NgModule({
  declarations: [DSTooltipDirective, DSTooltipComponent],
  imports: [NgIf],
  exports: [DSTooltipDirective, DSTooltipComponent],
})
export class DSTooltipModule {}

import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { DSDropdownEvents } from './enums';
import { CdkMenuTrigger, MENU_TRIGGER } from '@angular/cdk/menu';
import {
  BehaviorSubject,
  combineLatest,
  exhaustMap,
  fromEvent,
  merge,
} from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { ConnectedPosition, OverlayRef } from '@angular/cdk/overlay';
import { anyParentEvent, autoDestroy, followDestroy } from '../../shared';

@Directive({
  selector: '[cdkMenuTriggerFor][mOcloudDSDropdown]',
  exportAs: 'mOcloudDSDropdown',
})
@autoDestroy()
export class DSDropdownDirective implements OnInit, AfterViewInit {
  @Input()
  public fixedWidth = false;

  @Input()
  public customWidth: string | null = null;

  @Input()
  public allowBackDropClick = true;

  @Input()
  public offsetY = 0;

  @Input()
  public offsetX = 0;

  @Input()
  public event: DSDropdownEvents | `${DSDropdownEvents}` =
    DSDropdownEvents.CLICK;

  @Input()
  public set conditional(value: boolean) {
    this.conditional$.next(value);
  }

  public get overlayRef(): OverlayRef | null {
    return this.trigger['overlayRef'];
  }

  public set overlayRef(value: OverlayRef | null) {
    this.trigger['overlayRef'] = value;
  }

  public get positions(): ConnectedPosition[] {
    if (this.trigger.menuPosition?.length) return this.trigger.menuPosition;

    return [
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        offsetY: this.offsetY,
        offsetX: this.offsetX,
      },
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
        offsetY: this.offsetY,
        offsetX: this.offsetX,
      },
    ];
  }

  public readonly conditional$ = new BehaviorSubject(false);
  public __count = 0;

  public constructor(
    public readonly elementRef: ElementRef,
    @Inject(MENU_TRIGGER)
    public readonly trigger: CdkMenuTrigger
  ) {}

  public ngOnInit(): void {
    this.trigger.open = this.open.bind(this);
    this.trigger.close = this.close.bind(this);
    this.trigger.menuPosition = this.positions;
    combineLatest([
      fromEvent<Event>(this.elementRef.nativeElement, 'mouseover').pipe(
        filter(() => this.event === DSDropdownEvents.HOVER),
        tap((event) => {
          event.preventDefault();
          event.stopPropagation();
        }),
        exhaustMap(() => {
          this.open(false);
          return fromEvent<Event>(
            this.elementRef.nativeElement,
            'mouseleave'
          ).pipe(
            take(1),
            tap(() => this.close(true))
          );
        })
      ),
      fromEvent<Event>(this.elementRef.nativeElement, 'click').pipe(
        filter(() => this.event === DSDropdownEvents.CLICK),
        tap((event) => {
          event.preventDefault();
          event.stopPropagation();
        }),
        tap(() => {
          if (this.trigger.isOpen()) {
            if (this.__count > 0) {
              this.__count = 0;
              this.close(true);
            }
          } else {
            this.__count++;
            this.open(this.allowBackDropClick);
          }
        })
      ),
      this.conditional$.pipe(
        filter(() => this.event === DSDropdownEvents.CONDITIONAL),
        tap((value) => {
          value ? this.open(false) : this.close(true);
        })
      ),
    ])
      .pipe(followDestroy(this))
      .subscribe();
  }

  public ngAfterViewInit(): void {
    merge(
      anyParentEvent(this.elementRef.nativeElement, 'scroll'),
      fromEvent(window, 'resize')
    )
      .pipe(
        tap(() => this.overlayRef?.updatePosition()),
        followDestroy(this)
      )
      .subscribe();
  }

  public open(allowBackdropClick: boolean = true): void {
    if (!this.trigger.isOpen() && this.trigger.menuTemplateRef) {
      this.trigger.opened.next();

      this.overlayRef =
        this.overlayRef ||
        this.trigger['_overlay'].create(this.trigger['_getOverlayConfig']());
      this.overlayRef?.attach(this.trigger['getMenuContentPortal']());
      this.syncWidth();

      allowBackdropClick &&
        this.overlayRef
          ?.outsidePointerEvents()
          .pipe(
            filter(
              (e) =>
                e.target != this.elementRef.nativeElement &&
                !this.elementRef.nativeElement.contains(e.target)
            ),
            tap((event) => {
              if (
                !this.trigger['isElementInsideMenuStack'](
                  event.target as Element
                )
              ) {
                this.trigger['menuStack'].closeAll();
              } else {
                this.trigger['_closeSiblingTriggers']();
              }
              this.close(true);
            }),
            takeUntil(this.trigger['stopOutsideClicksListener'])
          )
          .subscribe();
    }
  }

  public close(fromEvent: boolean = false): void {
    if (!fromEvent) {
      return;
    }

    if (this.trigger.isOpen()) {
      this.trigger.closed.next();
      this.overlayRef?.detach();
    }
    this.trigger['_closeSiblingTriggers']();
  }

  protected syncWidth(): void {
    if (!this.overlayRef || (!this.fixedWidth && !this.customWidth)) {
      return;
    }

    const refRect = this.elementRef.nativeElement.getBoundingClientRect();
    this.overlayRef.updateSize({
      width: this.customWidth != null ? this.customWidth : refRect.width,
    });
  }
}

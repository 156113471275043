import { Inject, Injectable } from '@angular/core';
import { mergeMap, Observable } from 'rxjs';
import {
  EditableField,
  IUpdateUserPayload,
  IUser,
  IUsersSearchCondition,
} from '@main-data-access-interfaces';
import { HttpClient } from '@angular/common/http';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@main-data-access-resolvers';
import { SearchResult } from '@main-data-access-models';
import { IUserService } from './user-service.interface';
import { UserRoles } from '@main-data-access-enums';

@Injectable()
export class UserService implements IUserService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endpointResolver: IApiEndpointResolver,
    private readonly _httpClient: HttpClient
  ) {}

  //#endregion Constructor

  //#region Methods

  public getAsync(
    conditions: Partial<IUsersSearchCondition>
  ): Observable<SearchResult<IUser>> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/users/search`;
        return this._httpClient.post<SearchResult<IUser>>(apiUrl, conditions);
      })
    );
  }

  public createAsync(
    data: Omit<IUser, 'createdDate' | 'lastUpdateDt'>
  ): Observable<IUser> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/users/register`;
        return this._httpClient.post<IUser>(apiUrl, data);
      })
    );
  }

  public updateAsync(
    email: string,
    options: IUpdateUserPayload
  ): Observable<IUser> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/users/${email}`;
        return this._httpClient.put<IUser>(apiUrl, options);
      })
    );
  }

  public deleteAsync(email: string): Observable<never> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/users/${email}`;
        return this._httpClient.delete<never>(apiUrl);
      })
    );
  }

  //#endregion Methods
}

<section class="ds-date-picker-range">
  <aside>
    <m-ocloud-ds-date-picker-menu
      (itemClick)="selectMenuItem($event)"
    ></m-ocloud-ds-date-picker-menu>
  </aside>
  <section>
    <main>
      <article>
        <m-ocloud-ds-date-picker-navigation
          [(value)]="range.start"
          [min]="leftMin"
          [max]="leftMax"
        ></m-ocloud-ds-date-picker-navigation>
        <m-ocloud-ds-date-picker-date
          [value]="value"
          [type]="'range'"
          [min]="min"
          [max]="range.end"
          (selectedDate)="selectDate($event, 'start')"
          [date]="range.start"
        ></m-ocloud-ds-date-picker-date>
      </article>
      <article>
        <m-ocloud-ds-date-picker-navigation
          [(value)]="range.end"
          [min]="rightMin"
          [max]="rightMax"
        ></m-ocloud-ds-date-picker-navigation>
        <m-ocloud-ds-date-picker-date
          [value]="value"
          [type]="'range'"
          [min]="range.start"
          [max]="max"
          (selectedDate)="selectDate($event, 'end')"
          [date]="range.end"
        ></m-ocloud-ds-date-picker-date>
      </article>
    </main>
    <footer>
      <m-ocloud-ds-date-picker-value
        [text]="text"
        [type]="'range'"
        [isPlaceholder]="isPlaceholder"
      ></m-ocloud-ds-date-picker-value>
      <m-ocloud-ds-date-picker-actions
        (applyClick)="applyClick.emit()"
        (cancelClick)="cancel()"
      ></m-ocloud-ds-date-picker-actions>
    </footer>
  </section>
</section>

import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { v4 as uuid } from 'uuid';
import { IDownloadService } from './download-service.interface';

@Injectable()
export class DownloadService implements IDownloadService {
  //#region Constructor

  public constructor(protected readonly _httpClient: HttpClient) {}

  //#endregion

  //#region Methods

  public downloadAsync(httpRequest: HttpRequest<any>): Observable<void> {
    const clonedRequest = httpRequest.clone({
      responseType: 'arraybuffer',
    });
    return this._httpClient.request(clonedRequest).pipe(
      filter((httpEvent) => httpEvent instanceof HttpResponse),
      map((httpEvent) => httpEvent as HttpResponse<any>),
      tap((httpResponse: HttpResponse<any>) => {
        // Get the content disposition header from the response.
        const szContentDisposition =
          httpResponse.headers.get('Content-Disposition') || '';
        const szMimeType =
          httpResponse.headers.get('Content-Type') ||
          'application/octet-stream';

        let fileName = '';
        const dispositionParts = szContentDisposition.split(';');
        if (dispositionParts.length > 1) {
          fileName = dispositionParts[1]
            .replace('filename=', '')
            .replace('"', '')
            .replace('"', '')
            .trim();
        }

        if (!fileName || !fileName.length) {
          fileName = `${uuid()}`;
        }

        const blob = new Blob([httpResponse.body], { type: szMimeType });
        saveAs(blob, fileName);
      }),
      map(() => void 0)
    );
  }

  //#endregion
}

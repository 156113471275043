export enum DSBadgeColors {
  GRAY = 'gray',
  PRIMARY = 'primary',
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  GRAY_BLUE = 'gray-blue',
  BLUE_LIGHT = 'blue-light',
  BLUE = 'blue',
  INDIGO = 'indigo',
  PURPLE = 'purple',
  PINK = 'pink',
  ORANGE = 'orange',
}

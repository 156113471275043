import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'm-ocloud-ds-alert-host',
  template: '',
  host: {
    '[id]': 'id',
    '[class]': '"flex flex-col relative gap-3"',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DSAlertHostComponent {
  @Input()
  public id = '';

  public constructor(public readonly viewContainerRef: ViewContainerRef) {}
}

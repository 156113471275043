import { Directive } from '@angular/core';
import { DSControlTypes } from './enums';

@Directive()
export abstract class DSControlDirective<TType extends any = string> {
  public abstract get value(): TType;
  public abstract get isReadonly(): boolean;
  public abstract get isDisabled(): boolean;

  public abstract controlType: DSControlTypes;
  public abstract hasError: boolean;
}

import { ASSETS_FOLDER } from './folder';
export const ASSETS_IMAGES_URL = `${ASSETS_FOLDER}/images`;
export const ASSETS_IMAGES = {
  BACKGROUND: {
    HEADER: `${ASSETS_IMAGES_URL}/background/header.jpeg`,
    LOGIN_PAGE: `${ASSETS_IMAGES_URL}/background/login.png`,
  },
  AUTHENTICATION_STATUS: {
    SUCCESS: `${ASSETS_IMAGES_URL}/authentication/authentication-success.svg`,
    FAILED: `${ASSETS_IMAGES_URL}/authentication/authentication-fail.svg`,
    AUTHENTICATING: `${ASSETS_IMAGES_URL}/authentication/authenticating.svg`,
  },
  LOGO: {
    WITH_BRAND: `${ASSETS_IMAGES_URL}/logo.png`,
    WITHOUT_BRAND: `${ASSETS_IMAGES_URL}/logo-only.png`,
  },
} as const;

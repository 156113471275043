<section class="ds-pagination-full-page">
  <aside>
    <span>{{ itemsText }}</span>
  </aside>
  <main>
    <ng-content></ng-content>
    <m-ocloud-ds-button-group [ignoreItemBorder]="true">
      <ng-template mOcloudDSButtonGroupItem kind="custom" [disableState]="true">
        <div
          #selectDropdown="mOcloudDSDropdown"
          [cdkMenuTriggerFor]="paginationSelectTemplate"
          mOcloudDSDropdown
          (cdkMenuOpened)="isOpen = true"
          (cdkMenuClosed)="isOpen = false"
          [customWidth]="'200px'"
          [offsetX]="-16"
          [offsetY]="8"
        >
          <m-ocloud-ds-select-box
            [text]="(index + 1).toString()"
            [animate]="isOpen"
            [gap]="'2px'"
            [hiddenPlaceHolder]="showInput"
          >
            <ng-container *ngIf="showInput">
              <ng-template mOcloudDSSelectPrefix>
                <input
                  type="number"
                  [value]="termPage"
                  (input)="inputPage($event)"
                  [class.has-error]="errorInput"
                  class="ds-pagination-full-page__input"
                />
              </ng-template>
            </ng-container>
          </m-ocloud-ds-select-box>
        </div>
        <ng-template #paginationSelectTemplate>
          <m-ocloud-ds-select-container
            [maxHeight]="'300px'"
            [selectedValue]="index"
            [data]="items"
            (itemClick)="
              !$event.disabled && selectPage($event.value || 0);
              !$event.disabled && closeOnSelected && selectDropdown.close(true)
            "
          ></m-ocloud-ds-select-container>
        </ng-template>
      </ng-template>
      <ng-template
        mOcloudDSButtonGroupItem
        kind="custom"
        customClasses="!pl-0"
        [disableState]="true"
      >
        <span>of {{ pages }} {{ pageText }}</span>
      </ng-template>
    </m-ocloud-ds-button-group>
    <m-ocloud-ds-button-group>
      <ng-template
        (clickEvent)="previous()"
        mOcloudDSButtonGroupItem
        kind="icon-large"
        [disableSelectAction]="true"
        [instance]="'arrow-left'"
        [instanceCategory]="'outline'"
      ></ng-template>
      <ng-template
        (clickEvent)="next()"
        mOcloudDSButtonGroupItem
        kind="icon-large"
        [disableSelectAction]="true"
        [instance]="'arrow-right-1'"
        [instanceCategory]="'outline'"
      ></ng-template>
    </m-ocloud-ds-button-group>
  </main>
</section>

import { Route } from '@angular/router';
import {
  AuthenticatedUserGuard,
  UnauthenticatedUserGuard,
} from '@main-logics-guards';

export const routes: Route[] = [
  {
    path: 'authentication',
    canActivate: [UnauthenticatedUserGuard],
    loadComponent: () =>
      import('./modules').then((m) => m.AuthenticationComponent),
    loadChildren: () => import('./modules').then((m) => m.authenticationRoutes),
  },
  {
    path: '',
    canActivate: [AuthenticatedUserGuard],
    loadComponent: () => import('./modules').then((m) => m.PortalComponent),
    loadChildren: () => import('./modules').then((m) => m.portalRoutes),
  },
];

import { ComponentRef, Inject, Injectable } from '@angular/core';
import {
  IDSAlertComponent,
  IDSAlertService,
  IDSAlertServiceConfig,
  IDSAlertServiceOption,
  IDSAlertServiceOutput,
} from './interfaces';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import {
  DS_APPLICATION_SERVICE_TOKEN,
  IDSApplicationService,
} from '../../shared';
import { DOCUMENT } from '@angular/common';
import {
  DSAlertColors,
  DSAlertFooterTypes,
  DSAlertHorizontalPositions,
  DSAlertSizes,
  DSAlertVerticalPositions,
} from './enums';
import { DS_ALERT_SERVICE_CONFIG_TOKEN } from './constants';
import { ComponentPortal } from '@angular/cdk/portal';
import { DSAlertHostComponent } from './alert-host.component';
import { DSButtonAppearances } from '../../common/button';
import { DSAlertComponent } from './alert.component';
import { take, tap } from 'rxjs/operators';

@Injectable()
export class DSAlertService implements IDSAlertService {
  public constructor(
    private readonly _overlay: Overlay,
    @Inject(DS_APPLICATION_SERVICE_TOKEN)
    private readonly _applicationService: IDSApplicationService,
    @Inject(DOCUMENT)
    private readonly _document: Document
  ) {}

  public error<TOutput, TContent, TData>(
    title: string,
    content?: IDSAlertServiceConfig<TData, TContent>['content'],
    option?: Partial<IDSAlertServiceConfig<TData, TContent>['option']>
  ): IDSAlertServiceOutput<TOutput> {
    return this.open<TOutput, TContent, TData>(
      title,
      DSAlertColors.ERROR,
      content,
      option
    );
  }

  public info<TOutput, TContent, TData>(
    title: string,
    content?: IDSAlertServiceConfig<TData, TContent>['content'],
    option?: Partial<IDSAlertServiceConfig<TData, TContent>['option']>
  ): IDSAlertServiceOutput<TOutput> {
    return this.open<TOutput, TContent, TData>(
      title,
      DSAlertColors.DEFAULT,
      content,
      option
    );
  }

  public neutral<TOutput, TContent, TData>(
    title: string,
    content?: IDSAlertServiceConfig<TData, TContent>['content'],
    option?: Partial<IDSAlertServiceConfig<TData, TContent>['option']>
  ): IDSAlertServiceOutput<TOutput> {
    return this.open<TOutput, TContent, TData>(
      title,
      DSAlertColors.GRAY,
      content,
      option
    );
  }

  public primary<TOutput, TContent, TData>(
    title: string,
    content?: IDSAlertServiceConfig<TData, TContent>['content'],
    option?: Partial<IDSAlertServiceConfig<TData, TContent>['option']>
  ): IDSAlertServiceOutput<TOutput> {
    return this.open<TOutput, TContent, TData>(
      title,
      DSAlertColors.PRIMARY,
      content,
      option
    );
  }

  public success<TOutput, TContent, TData>(
    title: string,
    content?: IDSAlertServiceConfig<TData, TContent>['content'],
    option?: Partial<IDSAlertServiceConfig<TData, TContent>['option']>
  ): IDSAlertServiceOutput<TOutput> {
    return this.open<TOutput, TContent, TData>(
      title,
      DSAlertColors.SUCCESS,
      content,
      option
    );
  }

  public warning<TOutput, TContent, TData>(
    title: string,
    content?: IDSAlertServiceConfig<TData, TContent>['content'],
    option?: Partial<IDSAlertServiceConfig<TData, TContent>['option']>
  ): IDSAlertServiceOutput<TOutput> {
    return this.open<TOutput, TContent, TData>(
      title,
      DSAlertColors.WARNING,
      content,
      option
    );
  }

  public open<TOutput, TContent, TData>(
    title: string,
    color: IDSAlertServiceOption['color'],
    content?: IDSAlertServiceConfig<TData, TContent>['content'],
    option?: Partial<IDSAlertServiceConfig<TData, TContent>['option']>
  ): IDSAlertServiceOutput<TOutput> {
    const config = this._getDefaultConfig(title, color, content, option);
    const container = this._getContainer(config.option);

    const injector = this._applicationService.createInjector([
      {
        provide: DS_ALERT_SERVICE_CONFIG_TOKEN,
        useValue: config,
      },
    ]);

    const componentRef = this._applicationService.createComponentRef<
      typeof injector,
      DSAlertComponent<TOutput, TContent, TData>
    >(DSAlertComponent, injector);

    this._applicationService.attachHostView(componentRef.hostView);

    const element = this._applicationService.getHostViewElement(
      componentRef.hostView
    );

    this._applicationService.appendTo(element, container);

    const instance = componentRef.instance;

    let timeout: ReturnType<typeof setTimeout>;

    instance.closeClick
      .pipe(
        take(1),
        tap(() => timeout && clearTimeout(timeout)),
        tap(() => {
          instance.animationStatus = 'leave';
          componentRef.changeDetectorRef.markForCheck();
          timeout = setTimeout(() => {
            this._applicationService.detachHostView(componentRef.hostView);
            this._applicationService.removeFrom(element, container);
            componentRef.destroy();
            clearTimeout(timeout);
          }, 300);
        })
      )
      .subscribe();

    if (option?.duration) {
      timeout = setTimeout(() => {
        instance.closeClick.emit();
      }, option.duration);
    }

    return {
      afterDismissButtonClick: instance.dismissClick.asObservable(),
      afterChangeButtonClick: instance.changeClick.asObservable(),
      afterCloseButtonClick: instance.closeClick.asObservable(),
      afterClosed: instance.closed.asObservable(),
      afterOpened: instance.opened.asObservable(),
    };
  }

  protected _getDefaultConfig<TData, TContent>(
    title: string,
    color: IDSAlertServiceOption['color'],
    content?: IDSAlertServiceConfig<TData, TContent>['content'],
    option?: Partial<IDSAlertServiceConfig<TData, TContent>['option']>
  ): IDSAlertServiceConfig<TData, TContent> {
    return {
      option: {
        title,
        description: (content ||
          option?.description ||
          '') as IDSAlertComponent['description'],
        iconTemplate: null,
        size: DSAlertSizes.FLOATING,
        color,
        dismissButtonAppearance: DSButtonAppearances.SECONDARY_GRAY,
        changeButtonAppearance: DSButtonAppearances.PRIMARY,
        dismissLabel: '',
        changeLabel: '',
        duration: 2000,
        footerType: DSAlertFooterTypes.LINK,
        horizontalOffset: '12px',
        verticalOffset: '12px',
        horizontalPosition: DSAlertHorizontalPositions.RIGHT,
        verticalPosition: DSAlertVerticalPositions.TOP,
        closeOnDismissClick: false,
        closeOnChangeClick: false,
        ...(option || {}),
      },
      content,
    };
  }

  protected _generateId(option: IDSAlertServiceOption): string {
    return `ds-alert-host-${option.horizontalPosition}-${option.verticalPosition}`;
  }

  protected _getContainer<TElement extends HTMLElement = HTMLElement>(
    option: IDSAlertServiceOption
  ): TElement {
    const id = this._generateId(option);

    const existed = this._document.getElementById(id);

    if (existed) {
      return existed as TElement;
    }

    const overlayRef = this._createOverlay(option);

    const containerPortal = new ComponentPortal(DSAlertHostComponent);

    const containerRef: ComponentRef<DSAlertHostComponent> =
      overlayRef.attach(containerPortal);
    const instance = containerRef.instance;
    instance.id = id;

    return this._applicationService.getHostViewElement(containerRef.hostView);
  }

  protected _createOverlay(option: IDSAlertServiceOption): OverlayRef {
    const overlayConfig = new OverlayConfig();

    const positionStrategy = this._overlay.position().global();

    const isLeft =
      option.horizontalPosition === 'left' ||
      option.horizontalPosition === 'start';
    const isCenter = option.horizontalPosition === 'center';
    const isRight = !isLeft && !isCenter;

    if (isLeft) {
      positionStrategy.left(option.horizontalOffset);
    } else if (isRight) {
      positionStrategy.right(option.horizontalOffset);
    } else {
      positionStrategy.centerHorizontally(option.horizontalOffset);
    }

    if (option.verticalPosition === DSAlertVerticalPositions.TOP) {
      positionStrategy.top(option.verticalOffset);
    } else if (option.verticalPosition === DSAlertVerticalPositions.BOTTOM) {
      positionStrategy.bottom(option.verticalOffset);
    } else {
      positionStrategy.centerVertically(option.verticalOffset);
    }

    overlayConfig.positionStrategy = positionStrategy;
    return this._overlay.create(overlayConfig);
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DSControlTypes, DSControlDirective } from '../control';
import { isEqual } from 'lodash-es';
import { v4 } from 'uuid';
import { DSCheckboxSizes } from './enums';

@Component({
  selector: 'm-ocloud-ds-checkbox',
  templateUrl: './checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DSCheckboxComponent),
      multi: true,
    },
    {
      provide: DSControlDirective,
      useExisting: DSCheckboxComponent,
    },
  ],
  host: {
    '[class]': '"contents"',
  },
})
export class DSCheckboxComponent
  extends DSControlDirective<boolean>
  implements ControlValueAccessor
{
  @Input()
  public id = v4();

  @Input()
  public label = '';

  @Input()
  public description = '';

  @Input()
  public value = false;

  @Input()
  public size: DSCheckboxSizes | `${DSCheckboxSizes}` = DSCheckboxSizes.SM;

  @Input()
  public hasError = false;

  @Input()
  public disabled = false;

  @Input()
  public readOnly = false;

  @Input()
  public forceChecked: boolean | null = null;
  public get isReadonly(): boolean {
    return this.readOnly;
  }
  public get isDisabled(): boolean {
    return this.disabled;
  }

  public controlType: DSControlTypes = DSControlTypes.CHECKBOX;
  public _onTouched: () => void = () => {};
  public _onChange: (value: boolean) => void = () => {};

  public registerOnChange(fn: (value: boolean) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: boolean): void {
    if (isEqual(value, this.value)) {
      return;
    }

    this.value = value;
    this._onChange(value);
    this._onTouched();
  }

  public select(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.writeValue(Boolean(target.value));
  }
}

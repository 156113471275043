<label
  class="ds-select-box {{ customClasses }}"
  [style.--ds-select-box-gap]="gap"
>
  <ng-container *ngIf="prefixTemplate?.templateRef as templateRef">
    <ng-container *ngTemplateOutlet="templateRef"></ng-container>
  </ng-container>
  <span
    class="ds-select-box__label"
    *ngIf="!hiddenPlaceHolder"
    [class.is-placeholder]="isPlaceholder"
  >
    {{ isPlaceholder ? placeHolder : text }}
  </span>
  <i
    [mOcloudDSIcon]="'arrow-down-1'"
    [category]="'outline'"
    class="ds-select-box__icon"
    [class.animate]="animate"
  ></i>
</label>

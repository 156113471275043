import { createFeatureSelector, createSelector } from '@ngrx/store';
import { portalLayoutStoreKey } from './portal-layout.reducer';
import { IPortalLayoutStoreState } from './portal-layout.state';

const portalLayoutStoreSelector =
  createFeatureSelector<IPortalLayoutStoreState>(portalLayoutStoreKey);

export class PortalLayoutSelectors {
  //#region Properties

  public static readonly isCollapsed = createSelector(
    portalLayoutStoreSelector,
    (state) => state.collapse
  );

  public static readonly loading = createSelector(
    portalLayoutStoreSelector,
    (state) => state.loading
  );

  //#endregion
}

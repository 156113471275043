<section class="ds-date-picker-navigation">
  <button
    [mOcloudDSButtonIcon]="'tertiary-gray'"
    [size]="'sm'"
    [disabled]="disabledPrevious"
    (click)="previous()"
  >
    <i [mOcloudDSIcon]="'arrow-left-2'" [category]="'outline'"></i>
  </button>
  <h5>{{ value | date: 'MMMM yyyy' }}</h5>
  <button
    [mOcloudDSButtonIcon]="'tertiary-gray'"
    [size]="'sm'"
    [disabled]="disabledNext"
    (click)="next()"
  >
    <i [mOcloudDSIcon]="'arrow-right-3'" [category]="'outline'"></i>
  </button>
</section>

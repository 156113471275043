import {NgModule} from "@angular/core";
import {PATIENT_SERVICE} from "./patient-service.constants";
import {PatientService} from "./patient.service";

@NgModule({
  providers: [
    {
      provide: PATIENT_SERVICE,
      useClass: PatientService
    }
  ]
})
export class PatientServiceModule {
}

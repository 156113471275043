import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Directive()
export class DSPaginationDirective {
  @Output()
  public readonly indexChange = new EventEmitter<number>();

  @Input()
  public itemsPerPage = 10;

  @Input()
  public total = 0;

  @Input()
  public index = 0;

  public get pages(): number {
    if (this.total <= 0) {
      return 0;
    }

    if (this.total <= this.itemsPerPage) {
      return 1;
    }

    const mod = this.total % this.itemsPerPage;
    return (
      (this.total + (mod > 0 ? this.itemsPerPage - mod : 0)) / this.itemsPerPage
    );
  }

  public constructor(protected readonly _cdr: ChangeDetectorRef) {}

  public selectPage(page: number): void {
    this.index = page;
    this.indexChange.emit(this.index);
    this._cdr.markForCheck();
  }

  public next(): void {
    if (this.index < this.pages - 1) {
      this.index += 1;
      this.indexChange.emit(this.index);
    }
  }

  public previous(): void {
    if (this.index > 0) {
      this.index -= 1;
      this.indexChange.emit(this.index);
    }
  }
}

<article
  class="ds-alert ds-alert--{{ size }} ds-alert--{{ color }} ds-alert--{{
    animationStatus
  }}"
>
  <button class="ds-alert__close" (click)="close()">
    <i [mOcloudDSIcon]="'x'" [category]="'custom'"></i>
  </button>
  <header>
    <ng-container *ngIf="iconTemplate; else iconTemplate">
      <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
    </ng-container>
    <ng-template #iconTemplate>
      <span class="ds-alert__icon" [inlineSVG]="colorIconLink"></span>
    </ng-template>
  </header>
  <section class="ds-alert__container">
    <main>
      <ng-container *ngIf="title">
        <ng-container *ngIf="isTemplate(title); else titleTemplate">
          <ng-container *ngTemplateOutlet="$any(title)"></ng-container>
        </ng-container>
        <ng-template #titleTemplate>
          <h5>{{ title }}</h5>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="description">
        <ng-container *ngIf="isTemplate(description); else descriptionTemplate">
          <ng-container *ngTemplateOutlet="$any(description)"></ng-container>
        </ng-container>
        <ng-template #descriptionTemplate>
          <p>{{ description }}</p>
        </ng-template>
      </ng-container>
    </main>
    <footer>
      <ng-container [ngSwitch]="footerType">
        <ng-container *ngSwitchCase="'link'">
          <a (click)="dismiss()">{{ dismissLabel }}</a>
          <a (click)="change()">{{ changeLabel }}</a>
        </ng-container>
        <ng-container *ngSwitchCase="'button'">
          <button
            (click)="dismiss()"
            [mOcloudDSButton]="dismissButtonAppearance"
            [size]="'md'"
          >
            {{ dismissLabel }}
          </button>
          <button
            (click)="change()"
            [mOcloudDSButton]="changeButtonAppearance"
            [size]="'md'"
          >
            {{ changeLabel }}
          </button>
        </ng-container>
      </ng-container>
    </footer>
  </section>
</article>

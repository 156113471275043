import { NgModule } from '@angular/core';
import { DSButtonGroupComponent } from './button-group.component';
import { DSButtonGroupItemDirective } from './button-group-item.directive';
import {
  AsyncPipe,
  NgForOf,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgTemplateOutlet,
} from '@angular/common';
import { DSIconModule } from '../icon';

@NgModule({
  declarations: [DSButtonGroupComponent, DSButtonGroupItemDirective],
  imports: [
    NgForOf,
    NgIf,
    DSIconModule,
    AsyncPipe,
    NgSwitch,
    NgSwitchDefault,
    NgSwitchCase,
    NgTemplateOutlet,
  ],
  exports: [DSButtonGroupComponent, DSButtonGroupItemDirective],
})
export class DSButtonGroupModule {}

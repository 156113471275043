import { createAction, props } from '@ngrx/store';
import { IProfile } from '@main-data-access-interfaces';

const prefix = '[PROFILE]';

export class ProfileActions {
  //#region Properties

  public static readonly saveProfile = createAction(
    `${prefix} save profile`,
    props<{ profile: IProfile }>()
  );

  //#endregion
}

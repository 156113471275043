import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { IFeatureSentinelService } from '@ui-tool/core';
import { Store } from '@ngrx/store';
import { IProfileState, ProfileSelectors } from '@main-data-access-stores';

@Injectable()
export class FeatureSentinelService implements IFeatureSentinelService {
  //#region Properties

  protected readonly _doValidationEvent: Subject<void>;

  //#endregion

  //#region Constructor

  public constructor(protected readonly _store: Store<IProfileState>) {
    this._doValidationEvent = new ReplaySubject(1);
  }

  //#endregion

  //#region Methods

  public doValidation(): void {
    this._doValidationEvent.next();
  }

  public hookValidationEventAsync(): Observable<void> {
    return this._doValidationEvent.asObservable();
  }

  public ableToAccessFeaturesAsync(
    designatedFeatures: string[]
  ): Observable<boolean> {
    if (!designatedFeatures || !designatedFeatures.length) {
      return of(true);
    }

    return this._store.select(ProfileSelectors.profile).pipe(
      map((profile) => {
        if (!profile || !profile.features) {
          return false;
        }
        const itemIndex = profile.features.findIndex(
          (assignedFeature: string) => {
            return (
              designatedFeatures.findIndex(
                (designatedFeature) => designatedFeature === assignedFeature
              ) !== -1
            );
          }
        );
        return itemIndex !== -1;
      }),
      catchError(() => of(false))
    );
  }

  //#endregion
}

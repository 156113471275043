import {
  Directive,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  ViewContainerRef,
} from '@angular/core';
import { DSTooltipDirections, DSTooltipThemes } from './enums';
import { Overlay, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { DSTooltipComponent } from './tooltip.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { DS_TOOLTIP_DATA_TOKEN } from './tokens';

@Directive({
  selector: '[mOcloudDSTooltip]',
})
export class DSTooltipDirective implements OnDestroy {
  @Input('mOcloudDSTooltip')
  public label = '';

  @Input()
  public description: string | null = null;

  @Input()
  public theme: DSTooltipThemes | `${DSTooltipThemes}` = DSTooltipThemes.DARK;

  @Input()
  public direction: DSTooltipDirections | `${DSTooltipDirections}` =
    DSTooltipDirections.BOTTOM_CENTER;

  private _overlayRef: OverlayRef | null = null;

  public constructor(
    protected readonly element: ElementRef<HTMLElement>,
    protected readonly overlay: Overlay,
    protected readonly viewContainer: ViewContainerRef
  ) {}

  public ngOnDestroy(): void {
    this._overlayRef?.dispose();
  }

  @HostListener('mouseenter')
  @HostListener('focus')
  public showTooltip(): void {
    if (this._overlayRef?.hasAttached() === true) {
      return;
    }

    this.attachTooltip();
  }

  @HostListener('mouseleave')
  @HostListener('blur')
  public hideTooltip(): void {
    if (this._overlayRef?.hasAttached() === true) {
      this._overlayRef?.detach();
    }
  }

  protected attachTooltip(): void {
    if (this._overlayRef === null) {
      const positionStrategy = this.getPositionStrategy();
      this._overlayRef = this.overlay.create({ positionStrategy });
    }

    const injector = Injector.create({
      providers: [
        {
          provide: DS_TOOLTIP_DATA_TOKEN,
          useValue: {
            label: this.label,
            description: this.description,
            theme: this.theme,
          },
        },
      ],
    });
    const component = new ComponentPortal(
      DSTooltipComponent,
      this.viewContainer,
      injector
    );
    this._overlayRef.attach(component);
  }

  protected getPositionStrategy(): PositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(this.element)
      .withPositions([
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          panelClass: 'top',
        },
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          panelClass: 'bottom',
        },
      ]);
  }
}

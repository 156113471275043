<section class="ds-select-container" *ngIf="data$ | async as data">
  <ng-container *ngIf="filterMode">
    <m-ocloud-ds-field defaultWidth="100%" customClasses="ds-select-search">
      <input
        mOcloudDSInput
        type="text"
        role="searchbox"
        [placeholder]="filterPlaceHolder"
        [value]="filterKey"
        (input)="input($event)"
      />
    </m-ocloud-ds-field>
  </ng-container>
  <ol
    class="ds-select-list {{ customClasses }}"
    [style.--ds-select-container-max-height]="maxHeight"
  >
    <ng-container
      *ngFor="
        let item of data || []
          | mOcloudDSSelectFilter: { filterBy, filterKey, filterMode }
      "
    >
      <li (click)="itemClick.emit(item)">
        <m-ocloud-ds-select-item
          [isSelected]="selectedValue === item.value"
          [data]="item"
        ></m-ocloud-ds-select-item>
      </li>
    </ng-container>
    <ng-container *ngIf="filterMode === 'autocomplete' && filterKey">
      <li (click)="itemClick.emit(filterItem)">
        <m-ocloud-ds-select-item
          [isSelected]="selectedValue === filterItem.value"
          [data]="filterItem"
        ></m-ocloud-ds-select-item>
      </li>
    </ng-container>
  </ol>
</section>

import {FEATURE_SENTINEL_SERVICE, IFeatureSentinelService, IRequirementHandler} from '@ui-tool/core';
import {Inject, Injectable} from '@angular/core';
import {AppointmentFeatures, RequirementNames} from '@main-data-access-enums';
import {Observable} from 'rxjs';

@Injectable()
export class AbleToViewAppointmentsRequirementHandler implements IRequirementHandler {

  //#region Properties

  public readonly name = RequirementNames.ABLE_TO_VIEW_APPOINTMENTS;

  //#endregion

  //#region Constructor

  public constructor(@Inject(FEATURE_SENTINEL_SERVICE)
                     protected _featureSentinelService: IFeatureSentinelService) {
  }

  //#endregion

  //#region Methods

  public shouldRequirementMetAsync(): Observable<boolean> {
    return this._featureSentinelService.ableToAccessFeaturesAsync([AppointmentFeatures.SEARCH]);

  }

  //#endregion
}

import { NgModule } from '@angular/core';
import { DSCheckboxComponent } from './checkbox.component';
import { NgIf } from '@angular/common';

@NgModule({
  declarations: [DSCheckboxComponent],
  exports: [DSCheckboxComponent],
  imports: [NgIf],
})
export class DSCheckboxModule {}

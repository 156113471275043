import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  DSSelectItemKinds,
  DSSelectItemTypes,
  IDSSelectItem,
} from '../../../form';
import { DSIconCategories } from '../../icon';
import { DSPaginationDirective } from '../pagination.directive';
import { debounceTime, Subject } from 'rxjs';
import { autoDestroy, followDestroy } from '@design-system/shared';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'm-ocloud-ds-pagination-full-page',
  templateUrl: './pagination-full-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '"contents"',
  },
})
@autoDestroy()
export class DSPaginationFullPageComponent
  extends DSPaginationDirective
  implements OnInit
{
  @Input()
  public closeOnSelected = true;

  @Input()
  public maxPageToShowInput = 20;

  public isOpen = false;

  public errorInput = false;

  public get termPage(): number {
    if (this._termPage) {
      return this._termPage;
    }

    return this.index + 1;
  }

  public get showInput(): boolean {
    return this.pages > this.maxPageToShowInput;
  }

  public get items(): IDSSelectItem<number>[] {
    return Array.from({ length: this.pages }).map((_, i) => ({
      text: `${i + 1}`,
      value: i,
      kind: DSSelectItemKinds.DEFAULT,
      type: DSSelectItemTypes.CHECKBOX,
      icon: 'tick-square',
      iconCategory: DSIconCategories.BOLD,
    }));
  }

  public get pageText(): string {
    return this.pages > 1 ? 'pages' : 'page';
  }

  public get itemsText(): string {
    const first = this.index * this.itemsPerPage + 1;
    const last = (this.index + 1) * this.itemsPerPage;
    return `${
      this.total > this.itemsPerPage
        ? `${first}-${last > this.total ? this.total : last}`
        : this.total > 0
        ? `1-${this.total}`
        : '1-1'
    } of ${this.total} ${this.total > 1 ? 'items' : 'item'}`;
  }

  private readonly _termChange$ = new Subject<number>();

  private _termPage: number | null = null;

  public ngOnInit(): void {
    this._termChange$
      .pipe(
        followDestroy(this),
        debounceTime(250),
        tap((value) => {
          const valid = value >= 1 && value <= this.pages;
          if (valid) {
            this.errorInput = false;
            this._termPage = null;
            this.selectPage(value - 1);
            return;
          }

          this._termPage = value;
          this.errorInput = true;
          this._cdr.markForCheck();
        })
      )
      .subscribe();
  }

  public inputPage(event: Event): void {
    const { valueAsNumber } = event.target as HTMLInputElement;
    this._termChange$.next(valueAsNumber);
  }
}

import { NgModule } from "@angular/core";
import { APPOINTMENT_SERVICE } from "./appointment-service.constants";
import { AppointmentService } from "./appointment.service";

@NgModule({
  providers: [
    {
      provide: APPOINTMENT_SERVICE,
      useClass: AppointmentService
    }
  ]
})
export class AppointmentServiceModule { }

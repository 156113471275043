import {createReducer, on} from "@ngrx/store";
import {AppointmentDetailActions} from './appointment-detail.actions';
import {IAppointmentDetailState} from './appointment-detail.state';

//#region Properties

export const appointmentDetailStoreKey = 'appointmentDetailStoreKey';
const appointmentDetailState: IAppointmentDetailState = {
};

export const appointmentDetailReducer = createReducer(
  appointmentDetailState,
  on(AppointmentDetailActions.setAppointment, (state, action) => ({
    ...state,
    appointment: action.appointment
  }))
);

//#endregion

export enum RoutePaths {
  DEFAULT = '',
  DASHBOARD = 'dashboard',
  DETAIL = ':id',
  USER = 'user',
  DOCTOR = 'doctor',
  PATIENT = 'patient',
  APPOINTMENT = 'appointment',
  MY_CALENDAR = 'my-calendar',
  QUESTIONNAIRE = 'questionnaire',
  TRACKER = 'tracker',
}

import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AccessTokenInterceptor } from './access-token.interceptor';

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: AccessTokenInterceptor,
    },
  ]
})
export class AccessTokenInterceptorModule {}

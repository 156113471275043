export enum ScreenCodes {
  DASHBOARD = 'DASHBOARD',
  LOGIN = 'LOGIN',
  UNAVAILABLE_USER = 'UNAVAILABLE_USER',
  PATIENT_DETAIL = 'PATIENT_DETAIL',
  PATIENTS = 'PATIENTS',
  PATIENT_APPOINTMENT = 'PATIENT_APPOINTMENT',
  PATIENT_SYMPTOM = 'PATIENT_SYMPTOM',
  PATIENT_TREATMENT = 'PATIENT_TREATMENT',
  PATIENT_MEDICATION = 'PATIENT_MEDICATION',
  USERS = 'USERS',
  PATIENT_CLINICIAN = 'PATIENT_CLINICIAN',
}

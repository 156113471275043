import { Inject, Injectable } from '@angular/core';
import { ComponentStore, OnStateInit } from '@ngrx/component-store';
import { IPatientsComponentState } from './patients.state';
import { IPatientService, PATIENT_SERVICE } from '@main-data-access-services';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  EMPTY,
  finalize,
  map,
  of,
  pipe,
  switchMap,
  tap,
} from 'rxjs';
import { IPatientSearch } from '@main-data-access-interfaces';
import { PatientDetailNavigationRequest } from '@main-data-access-models';
import {
  ISmartNavigatorService,
  ISpinnerService,
  SMART_NAVIGATOR_SERVICE,
  SPINNER_SERVICE,
} from '@ui-tool/core';
import { DS_ALERT_SERVICE_TOKEN, IDSAlertService } from '@design-system';

@Injectable()
export class PatientsComponentStore
  extends ComponentStore<IPatientsComponentState>
  implements OnStateInit
{
  //#region Properties

  protected readonly _refresh$ = new BehaviorSubject<Date>(new Date());

  //#endregion Properties

  //#region Constructor

  public constructor(
    @Inject(PATIENT_SERVICE)
    protected readonly _patientService: IPatientService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    @Inject(SPINNER_SERVICE)
    protected readonly _spinnerService: ISpinnerService,
    @Inject(DS_ALERT_SERVICE_TOKEN)
    protected readonly _alertService: IDSAlertService
  ) {
    super({
      records: [],
      search: {
        status: 'all',
        treatmentType: 'all',
        doctorId: 'all',
        clinicCode: 'all',
        limit: 10,
        page: 1,
      },
      total: 0,
      loading: false,
    });
  }

  //#endregion Constructor

  //#region Lifecycle

  public ngrxOnStateInit(): void {
    this.load();
  }

  //#endregion Lifecycle

  //#region Methods

  public readonly updatePage = this.updater<
    IPatientsComponentState['search']['page']
  >((state, page) => ({
    ...state,
    search: {
      ...state.search,
      page,
    },
  }));

  public readonly updateLimit = this.updater<
    IPatientsComponentState['search']['limit']
  >((state, limit) => ({
    ...state,
    search: {
      ...state.search,
      limit,
    },
  }));

  public readonly updateSearch = this.updater<
    IPatientsComponentState['search']
  >((state, search) => ({
    ...state,
    search: {
      ...state.search,
      ...search,
      page: 1,
    },
  }));

  public readonly load = this.effect<never>(
    pipe(
      switchMap(() =>
        combineLatest([
          this.select((state) => state.search),
          this._refresh$,
        ]).pipe(
          map(([search]) => {
            Object.keys(search).forEach((key) => {
              const value = search[key as keyof IPatientSearch];
              if (value == null || value == '') {
                delete search[key as keyof IPatientSearch];
              }

              if (
                (key === 'status' ||
                  key === 'treatmentType' ||
                  key === 'doctorId' ||
                  key === 'clinicCode') &&
                value === 'all'
              ) {
                delete search[key as keyof IPatientSearch];
              }
            });
            return search;
          })
        )
      ),
      debounceTime(250),
      switchMap((search) => {
        this.patchState({
          loading: true,
        });
        return this._patientService.getAsync(search).pipe(
          tap((data) => {
            this.patchState({
              records: data.records,
              total: data.total,
            });
          }),
          catchError((exception) => {
            console.error(exception);
            this._alertService.error('Error', exception.message);
            return EMPTY;
          }),
          finalize(() => {
            this.patchState({
              loading: false,
            });
          })
        );
      })
    )
  );

  public readonly goToDetailPage = this.effect<string>(
    pipe(
      switchMap((mrn) => {
        const navigationRequest = new PatientDetailNavigationRequest(mrn);
        return this._navigationService
          .navigateToScreenAsync(navigationRequest)
          .pipe(
            catchError((exception) => {
              console.error(exception);
              return of(void 0);
            })
          );
      })
    )
  );

  public refresh(): void {
    this._refresh$.next(new Date());
  }

  //#endregion Methods
}

import { NgModule } from '@angular/core';
import { DSRadioComponent } from './radio.component';
import { NgIf } from '@angular/common';
import { DSRadioGroupComponent } from './radio-group.component';

@NgModule({
  declarations: [DSRadioComponent, DSRadioGroupComponent],
  exports: [DSRadioComponent, DSRadioGroupComponent],
  imports: [NgIf],
})
export class DSRadioModule {}

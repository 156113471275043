import { Inject, Injectable } from "@angular/core";
import { mergeMap, Observable } from "rxjs";
import { IDoctor, IDoctorsSearchCondition, ISearchCondition } from "@main-data-access-interfaces";
import { HttpClient } from "@angular/common/http";
import { API_ENDPOINT_RESOLVER, IApiEndpointResolver } from '@main-data-access-resolvers';
import { SearchResult } from "@main-data-access-models";
import { IDoctorService } from "./doctor-service.interface";

@Injectable()
export class DoctorService implements IDoctorService {

  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endpointResolver: IApiEndpointResolver,
    private readonly _httpClient: HttpClient
  ) {
  }

  //#endregion Constructor

  //#region Methods

  public getAsync(conditions: Partial<IDoctorsSearchCondition>): Observable<SearchResult<IDoctor>> {
    return this._endpointResolver.loadEndPointAsync('', '')
      .pipe(
        mergeMap(baseUrl => {
          const apiUrl = `${baseUrl}/doctors/search`;
          return this._httpClient.post<SearchResult<IDoctor>>(apiUrl, conditions);
        })
      );
  }

  public autocompleteAsync(value: string, options: Partial<ISearchCondition> = {
    orderBy: {
      direction: "ASC"
    },
    page: 1,
    limit: 50
  }): Observable<SearchResult<IDoctor>> {
    return this._endpointResolver.loadEndPointAsync('', '')
      .pipe(
        mergeMap(baseUrl => {
          const apiUrl = `${baseUrl}/doctors/autocomplete`;
          return this._httpClient.post<SearchResult<IDoctor>>(apiUrl, {
            value,
            ...options
          });
        })
      );
  }

  public updateAsync(doctorId: string, data: Partial<Omit<IDoctor, 'email' | 'doctorId'>>): Observable<IDoctor> {
    return this._endpointResolver.loadEndPointAsync('', '')
      .pipe(
        mergeMap(baseUrl => {
          const apiUrl = `${baseUrl}/doctors/${doctorId}`;
          return this._httpClient.put<IDoctor>(apiUrl, data);
        })
      );
  }

  public createAsync(data: Partial<Omit<IDoctor, 'lastUpdateDt' | 'createdDt' | 'doctorId'>>): Observable<IDoctor> {
    return this._endpointResolver.loadEndPointAsync('', '')
      .pipe(
        mergeMap(baseUrl => {
          const apiUrl = `${baseUrl}/doctors`;
          return this._httpClient.post<IDoctor>(apiUrl, data);
        })
      );
  }

  //#endregion Methods
}

export enum ExceptionCodes {
  AUTHENTICATION_PROVIDER_NOT_SUPPORT = 'AUTHENTICATION_PROVIDER_NOT_SUPPORT',
  ACCESS_TOKEN_NOT_FOUND = 'ACCESS_TOKEN_NOT_FOUND',
  ID_TOKEN_NOT_FOUND = 'ID_TOKEN_NOT_FOUND',
  ACCESS_TOKEN_EXPIRED = 'ACCESS_TOKEN_EXPIRED',
  PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND',
  PATIENT_NOT_FOUND = 'PATIENT_NOT_FOUND',
  APPOINTMENT_NOT_FOUND = 'APPOINTMENT_NOT_FOUND',
  DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_FEATURE = 'DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_FEATURE'
}

import { NgModule } from '@angular/core';
import { DSPaginationGroupComponent } from './pagination-group.component';
import { DSButtonModule } from '../../button';
import { DSIconModule } from '../../icon';
import { DSButtonGroupModule } from '../../button-group';
import { NgForOf } from '@angular/common';

@NgModule({
  declarations: [DSPaginationGroupComponent],
  imports: [DSButtonModule, DSIconModule, DSButtonGroupModule, NgForOf],
  exports: [DSPaginationGroupComponent],
})
export class DSPaginationGroupModule {}
